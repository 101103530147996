import {React, useState} from "react";
import Joi from "joi"
import {Input} from "../../elements/forms/Inputs"
import Form, {baseHandleChangeNested} from "../../elements/forms/Form"
import {useNavigate} from "react-router-dom";
import {urlRegEx} from "../../../utils/regularExpressions";
import {requestAudit} from "../../../api/audits";
import {toast} from "react-toastify";
import Alert from "../../elements/Alert";


const auditTypes = [{
    "title": "Product page",
    "disabled": false,
    "free_access": true,
    "instructions": "Enter a product page URL, usually this is something like https://yourdomain.com/products/your-product",
    "example_url": "https://yourdomain.com/products/your-product",
    "regEx": urlRegEx, //productPageUrlRegEx,
    "error": "Please enter a valid product page URL",
    "remarks": "",
    "code": "CLEVERCONVERSIONS"
}]

export default function RequestAuditCC() {

    const [data, setData] = useState({
        "audit_type": "CLEVERCONVERSIONS"
    })

    const [errors, setErrors] = useState({}) // validation errors
    const [auditValidationErrors, setAuditValidationErrors] = useState([]) // validation errors

    let navigate = useNavigate();

    // let auditTypes = getAuditTypes(currentUser, userInfo)
    let regExToUse = urlRegEx
    let urlErrorMessage = "Please enter a valid URL starting with http:// or https://"

    const schema = {
        audit_type: Joi.string().required().messages({
            "string.base": "Please select the audit type you'd like to run",
            "string.required": "Please select the audit type you'd like to run"
        }),
        url: Joi.string().regex(regExToUse).required()
            .messages({
                "object.regex": urlErrorMessage,
                "string.pattern.base": urlErrorMessage
            })
            .label("URL"),
        email: Joi.string().email({tlds: {allow: false}}).required()
    }

    const successMessage = "Success!"

    async function onSave() {
        try {
            let newData
            newData = await requestAudit(data)
            setAuditValidationErrors([])
            setData(newData)
            toast.success(successMessage)
            navigate(`/embedded/cc/audit/${newData.id}/${newData.secret}`)
        } catch (e) {
            if (e.data.audit_request_validation_errors) {
                setAuditValidationErrors(e.data.audit_request_validation_errors)
            }
            throw (e)
        }
    }

    function handleChange(evt) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema)
    }

    return (

        <div className="py-10">
            <div className="py-14 sm:py-20 lg:pb-24 border-b mb-12">
                <div className="lg:py-10 mx-auto max-w-7xl px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-4">
                    <div className="mx-auto max-w-4xl text-left col-span-3">
                        {/*<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">*/}
                        {/*    Audit your ecommerce store with the power of AI*/}
                        {/*</h1>*/}
                        {/*<p className="mt-6 text-lg leading-8 text-gray-600">*/}
                        {/*    ... and improve your conversion rates, average order value & overall customer*/}
                        {/*    experience.*/}
                        {/*</p>*/}
                        {/*<p className="text-lg leading-8 text-gray-600">*/}
                        {/*    Built for Shopify stores (but might work with other platforms too).*/}
                        {/*</p>*/}
                        <div className="mt-4 flex items-center justify-center gap-x-6">

                            <Form id="runAudit" onSave={onSave}
                                  hideSubmitButton={true}
                                  hideFormLayout={true}
                                  data={data} setData={setData}
                                  errors={errors} setErrors={setErrors}
                                  successMessage={successMessage}
                                  schema={schema}>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8 items-center">

                                    <Input
                                        path="url"
                                        type="url"
                                        label="URL to test:"
                                        placeholder={auditTypes.find(auditType => auditType.code === data.audit_type)?.example_url}
                                        helpText={auditTypes.find(auditType => auditType.code === data.audit_type)?.instructions}
                                        className={"text-left sm:col-span-4"}
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    <Input
                                        path="email"
                                        type="email"
                                        label="Your email address:"
                                        placeholder="Your email"
                                        helpText={"We will send the audit results to this email address."}
                                        className="sm:col-span-2 text-left"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    {auditValidationErrors.length > 0 &&
                                        <Alert extraClassName="py-2 sm:col-span-8" variant="danger">
                                            {auditValidationErrors.map((error, index) => (
                                                <span key={index} className="">{error}</span>
                                            ))}
                                        </Alert>}

                                    <div className="sm:col-span-2 -mt-2">
                                        <button
                                            type="submit"
                                            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                                    bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Test your store
                                        </button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
