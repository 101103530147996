import {
    AcademicCapIcon,
    Bars3CenterLeftIcon,
    BookOpenIcon,
    CalendarIcon,
    ChartBarIcon,
    CloudArrowDownIcon,
    DevicePhoneMobileIcon,
    EnvelopeIcon,
    FolderIcon,
    HomeIcon,
    HomeModernIcon,
    InboxIcon,
    PhotoIcon,
    QuestionMarkCircleIcon,
    ShoppingBagIcon,
    TableCellsIcon,
    UsersIcon,
    ClipboardDocumentListIcon,
    DocumentIcon,
    BookmarkSquareIcon,
    WrenchScrewdriverIcon,
    ChatBubbleLeftRightIcon,
    LifebuoyIcon,
    SquaresPlusIcon,
    BriefcaseIcon,
    BeakerIcon,
    PuzzlePieceIcon,
    CameraIcon,
    ReceiptPercentIcon,
    AdjustmentsHorizontalIcon,
    BellIcon, KeyIcon, UserCircleIcon
} from "@heroicons/react/24/outline";

const RocketLaunchIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-3 text-indigo-300">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"/>
    </svg>
}


// https://heroicons.com/
export const IconComponents = {
    users: UsersIcon,
    calendar: CalendarIcon,
    home: HomeIcon,
    folder: FolderIcon,
    inbox: InboxIcon,
    chartbar: ChartBarIcon,
    download: CloudArrowDownIcon,
    school: BeakerIcon,
    alumni: AcademicCapIcon,
    messenger: EnvelopeIcon,
    table: TableCellsIcon,
    questionmark: QuestionMarkCircleIcon,
    shop: ShoppingBagIcon,
    gallery: PhotoIcon,
    book: BookOpenIcon,
    boardingSchool: HomeModernIcon,
    device: DevicePhoneMobileIcon,
    clipboard: ClipboardDocumentListIcon,
    document: DocumentIcon,
    bookmark: BookmarkSquareIcon,
    tools: WrenchScrewdriverIcon,
    chat: ChatBubbleLeftRightIcon,
    help: LifebuoyIcon,
    squaresPlus: SquaresPlusIcon,
    briefcase: BriefcaseIcon,
    rocket: RocketLaunchIcon,
    puzzle: PuzzlePieceIcon,
    camera: CameraIcon,
    adjustments: AdjustmentsHorizontalIcon,
    percent: ReceiptPercentIcon,
    bars: Bars3CenterLeftIcon,
    bell: BellIcon,
    key: KeyIcon,
    user: UserCircleIcon
};


export const navigation = [
    { name: 'Start Audit', path: '/' },
    { name: 'Pricing', path: '/pricing', loggedInOnly: false},
    { name: 'Build Your Own Audit', path: '/build-your-own' },
    { name: 'Dashboard', path: '/dashboard', loggedInOnly: true},
]
