import React, {useEffect, useState} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {IconComponents} from "../../../routing/main_navigation"
import {useAuth} from "../../../contexts/AuthContext";
import {Page} from "../../layout/Pages";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export default function SettingsLayout() {

  const navigationItems = [
    {
      title: "Password",
      path: "/settings/change-password",
      icon: 'key',
      current: false,
    },
    {
      title: "Subscription",
      path: "/settings/subscription",
      icon: 'shop',
      current: false,
    },
  ]

  const location = useLocation();
  const [navigation, setNavigation] = useState(navigationItems);
  const {navigation: navItems} = useAuth();

  useEffect(() => {
    if (location.pathname && navItems) {
      const items = navigationItems.concat(navItems.user_navigation || []);
      const newNavigation = items.map((item) => {
        return {
          ...item,
          current: item.path === location.pathname,
        };
      });
      setNavigation(newNavigation);
    }
  }, [location, navItems]);

  return (
      <Page grey={true}>
    <div
      className="lg:grid lg:grid-cols-12 lg:gap-x-5 p-6 bg-gray-100"
      style={{
        minHeight: "calc(100vh - 121px)",
      }}
    >
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3 xl:col-span-2">
        <nav className="space-y-1">
          {navigation && navigation.map((item) => (
            <Link
              key={item.title}
              to={item.path}
              className={classNames(
                item.current
                  ? "bg-white text-indigo-700 hover:text-indigo-700 hover:bg-white"
                  : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
              )}
              aria-current={item.current ? "page" : undefined}
            >

              {item.icon ? (React.createElement(IconComponents[item.icon], {
                className: "text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6",
                'aria-hidden': true
              })) : null}

              {/*<item.icon*/}
              {/*  className={classNames(*/}
              {/*    item.current*/}
              {/*      ? "text-indigo-500 group-hover:text-indigo-500"*/}
              {/*      : "text-gray-400 group-hover:text-gray-500",*/}
              {/*    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"*/}
              {/*  )}*/}
              {/*  aria-hidden="true"*/}
              {/*/>*/}

              <span className="truncate">{item.title}</span>
            </Link>
          ))}
        </nav>
      </aside>

      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 xl:col-span-10">
        <Outlet />
      </div>
    </div>
      </Page>
  );
}
