import React, {useState} from "react";
import _ from "lodash";

import DatePicker, {registerLocale} from "react-datepicker";
import {Switch} from '@headlessui/react'
import classNames from 'classnames';
import de from "date-fns/locale/de";
import {default as ReactSelect} from "react-select";
import './Input.css';
import {useIsNarrower} from "../../../utils/useIsNarrower";
import {RadioGroup as HeadlessRadioGroup} from '@headlessui/react'
import {CheckIcon} from "@heroicons/react/16/solid";

registerLocale("de", de);


function getValue(rowId, data, path, isDate = false, useStudentId = false) {
    const row = rowId ? data.find(r => (useStudentId ? r.student.id : r.id) === rowId) : data
    const value = _.get(row, path);
    if (isDate) return value && new Date(_.get(row, path))
    return value
}

function getValueForStudent(studentId, data, path, isDate = false) {
    const row = studentId ? data.find(r => r.student.id === studentId) : data
    const value = _.get(row, path);

    if (isDate) return value && new Date(_.get(row, path))
    return value
}

function getError(rowId, errors, path, useStudentId = false) {
    let error = ""
    if (rowId) {
        const errorRow = errors ? errors.find(r => (useStudentId ? r.student.id : r.id) === rowId) : null
        error = errorRow ? _.get(errorRow.errors, path, "") || "" : ""
    } else {
        error = _.get(errors, path, "") || ""
    }
    return error
}

export function Input({
                          path,
                          label,
                          inputDivClassName,
                          errors,
                          className,
                          data,
                          inputClassName,
                          rowId,
                          onChange,
                          helpText,
                          useStudentId = false,
                          ...rest
                      }) {

    const error = getError(rowId, errors, path, useStudentId)
    const value = getValue(rowId, data, path, false, useStudentId)

    return (
        <div className={className}>
            <label htmlFor={path} className="block text-sm font-medium text-gray-900">
                {label}
            </label>

            {error && <div className="text-sm text-red-500">{error}</div>}

            <div className={inputDivClassName ? inputDivClassName : "mt-1"}>
                <input
                    id={path}
                    name={path}
                    value={value || ""}
                    className={
                        inputClassName
                            ? inputClassName
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    }
                    onChange={(evt) => onChange(evt, rowId)}
                    {...rest}
                />

                {helpText && <p className="text-xs text-gray-500">{helpText}</p>}
            </div>
        </div>
    );
}

export function TextArea({
                             path,
                             label,
                             inputDivClassName,
                             helpText,
                             className,
                             errors,
                             data,
                             inputClassName,
                             rowId,
                             onChange,
                             ...rest
                         }) {

    const error = getError(rowId, errors, path)
    const value = getValue(rowId, data, path)

    return (
        <div className={className}>
            <label htmlFor={path} className="block text-sm font-medium text-gray-900">
                {label}
            </label>
            <div className={inputDivClassName ? inputDivClassName : "mt-1"}>
                {error && <div className="text-sm text-red-500">{error}</div>}
                <textarea
                    id={path}
                    name={path}
                    value={value || ""}
                    className={
                        inputClassName
                            ? inputClassName
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    }
                    onChange={(evt) => onChange(evt, rowId)}
                    {...rest}
                />
            </div>
            {helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
        </div>
    );
}


export function RadioGroup({
                               path,
                               label,
                               options,
                               valueAttr,
                               labelAttr,
                               labelContent,
                               helpText,
                               className,
                               errors,
                               data,
                               onChange,
                               valueOnly,
                               singleValueAsList,
                               rowId,
                               tiles,
                               ...rest
                           }) {

    function handleChange(evt) {
        let newValue = evt.target.value
        if (singleValueAsList) {
            newValue = [parseInt(evt.target.value)]
        }
        let newTarget = {...evt.target, name: path, value: newValue}
        let newEvent = {...evt, target: newTarget}
        return onChange(newEvent, rowId)
    }

    function handleChangeTile(value) {
        let newValue = value
        if (singleValueAsList) {
            newValue = [parseInt(newValue)]
        }
        let evt = {target: {name: path, value: newValue}}
        let newTarget = {...evt.target, name: path, value: newValue}
        let newEvent = {...evt, target: newTarget}
        return onChange(newEvent, rowId)
    }

    const error = getError(rowId, errors, path)
    let value = getValue(rowId, data, path) || ""

    if (value && singleValueAsList) value = value[0]

    if (tiles) {
        return (
            <div className={className}>
                <div className="flex items-center justify-between mb-2">
                    <legend className="contents text-base font-medium text-gray-900">
                        {label}
                    </legend>
                    <p className="text-sm text-gray-500">{helpText}</p>
                </div>

                <HeadlessRadioGroup value={value} onChange={handleChangeTile} className="mt-2">
                    <HeadlessRadioGroup.Label className="sr-only">{label}</HeadlessRadioGroup.Label>
                    <div className="flex gap-2">
                        {options && options.map((option) => (
                            <HeadlessRadioGroup.Option
                                key={_.get(option, valueAttr)}
                                value={_.get(option, valueAttr)}
                                name={path + rowId}
                                className={
                                    classNames(
                                        'cursor-pointer focus:outline-none',
                                        _.get(option, valueAttr) === value
                                            ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                                            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                        'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium sm:flex-1'
                                    )
                                }
                            >
                                <div>
                                    <HeadlessRadioGroup.Label as="span" className="text-lg">{_.get(option, labelAttr)}</HeadlessRadioGroup.Label>
                                    {labelContent && <div className="mt-2 space-y-3 text-sm leading-6">
                                        {_.get(option, labelContent).split("\r\n").map((feature) => (
                                            <li key={feature} className="flex gap-x-3">
                                                <CheckIcon
                                                    className={
                                                        classNames(
                                                            'h-6 w-5 flex-none',
                                                            _.get(option, valueAttr) === value
                                                                ? 'text-white'
                                                                : 'text-indigo-600'
                                                        )
                                                    }
                                                    aria-hidden="true"
                                                />
                                                {feature}
                                            </li>
                                        ))}</div>}
                                </div>

                            </HeadlessRadioGroup.Option>
                        ))}
                    </div>
                </HeadlessRadioGroup>
            </div>
        )
    }

    return (
        <div className={className}>
            <fieldset className="mt-6">
                <legend className="contents text-base font-medium text-gray-900">
                    {label}
                </legend>
                <p className="text-sm text-gray-500">{helpText}</p>
                <div className="mt-4 space-y-4">
                    {options &&
                        options.map((option) => (
                            <div key={_.get(option, valueAttr)} className="flex items-center">
                                <input
                                    id={_.get(option, valueAttr)}
                                    value={_.get(option, valueAttr)}
                                    name={path + rowId}
                                    type="radio"
                                    checked={_.get(option, valueAttr) == value}
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor={_.get(option, valueAttr)}
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                    {_.get(option, labelAttr)}
                                </label>
                            </div>
                        ))}
                </div>
            </fieldset>
        </div>
    );
}

export function CheckboxGroup({
                                  path,
                                  label,
                                  options,
                                  valueAttr,
                                  labelAttr,
                                  helpText,
                                  className,
                                  errors,
                                  data,
                                  onChange,
                                  ...rest
                              }) {
    return (
        <div className={className}>
            <fieldset className="mt-6">
                <legend className="contents text-base font-medium text-gray-900">
                    {label}
                </legend>
                <p className="text-sm text-gray-500">{helpText}</p>
                <div className="mt-4 space-y-4">
                    {options &&
                        options.map((option) => (
                            <div key={_.get(option, valueAttr)} className="flex items-center">
                                <input
                                    id={_.get(option, valueAttr)}
                                    value={_.get(option, valueAttr)}
                                    checked={_.get(option, valueAttr) === _.get(data, path)}
                                    name={path}
                                    type="checkbox"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    onChange={onChange}
                                />
                                <label
                                    htmlFor={_.get(option, valueAttr)}
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                >
                                    {_.get(option, labelAttr)}
                                </label>
                            </div>
                        ))}
                </div>
            </fieldset>
        </div>
    );
}

export function Checkbox({
                             path,
                             label,
                             helpText,
                             className,
                             errors,
                             data,
                             onChange,
                             rowId,
                             hideLegend,
                             useStudentId=false,
                             ...rest
                         }) {

    const error = getError(rowId, errors, path, useStudentId)
    const value = getValue(rowId, data, path, false, useStudentId)

    return (
        <div className={className}>
            <fieldset className="">
                {!hideLegend && <legend className="block text-sm font-medium text-gray-900">
                    {label}
                </legend>}
                <p className="text-sm text-gray-500">{helpText}</p>

                {error && <div className="text-sm text-red-500">{error}</div>}

                <div className="mt-0 space-y-4">
                    <div className="flex items-center">
                        <input
                            id={path}
                            checked={value === true}
                            name={path}
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            onChange={(evt) => onChange(evt, rowId)}
                            {...rest}
                        />
                        <label
                            htmlFor={path}
                            className="ml-3 block text-sm font-medium text-gray-700"
                        >
                            {label}
                        </label>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}


export function Toggle({
                           path,
                           label,
                           className,
                           value,
                           onChange,
                           rowId,
                           ...rest
                       }) {

    const handleNotificationChange = () => {
        const obj = {
            target: {
                value: !value,
                name: path,
            },
            currentTarget: {
                type: "checkbox",
                checked: !value
            }
        }
        onChange(obj, rowId)
    }

    return (
        <div className={className}>
            <fieldset className="">
                <div>
                    <div>
                        <Switch
                            checked={value}
                            onChange={handleNotificationChange}
                            className={classNames(
                                value ? 'bg-blue-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            )}
                        >
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        value ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                />
                        </Switch>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}


export function Select({
                           path,
                           label,
                           options,
                           valueAttr,
                           labelAttr,
                           helpText,
                           emptyLabel,
                           className,
                           errors,
                           data,
                           onChange,
                           disabled,
                           valueOnly,
                           rowId,
                           ...rest
                       }) {

    function handleSelect(selectedOption) {
        if (!selectedOption)
            return onChange({target: {name: path, value: null}}, rowId);
        let value;
        if (valueOnly) value = selectedOption[valueAttr];
        else value = selectedOption;
        return onChange({target: {name: path, value}}, rowId);
    }

    const error = getError(rowId, errors, path)
    const value = getValue(rowId, data, path) || ""

    const selectedOption =
        options &&
        (valueOnly
            ? options.find((option) => option[valueAttr] === value)
            : options.find((option) => option[valueAttr] === value[valueAttr]));

    return (
        <div className={className}>
            {label ? (
                <label
                    htmlFor={value}
                    className="block text-sm font-medium text-gray-900 mb-1"
                >
                    {label}
                </label>
            ) : null}
            {error && <div className="text-sm text-red-500">{error}</div>}
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{menuPortal: base => ({...base, zIndex: 90})}}
                options={options}
                classNamePrefix={path}
                onChange={handleSelect}
                value={selectedOption || ""}
                getOptionValue={(option) => option[valueAttr]}
                getOptionLabel={(option) => option[labelAttr]}
                placeholder={emptyLabel || "Select"}
                isDisabled={disabled}
                isOptionDisabled={(option) => option.disabled}
                {...rest}
            />
        </div>
    );
}

export function MultiSelect({
                                path,
                                label,
                                options,
                                valueAttr,
                                labelAttr,
                                helpText,
                                emptyLabel,
                                className,
                                errors,
                                data,
                                onChange,
                                disabled,
                                valueOnly,
                                ...rest
                            }) {
    function handleSelect(selectedOptions, action) {
        let selectedValues = [];
        for (let i = 0; i < selectedOptions.length; i++) {
            if (valueOnly) selectedValues.push(selectedOptions[i][valueAttr]);
            else selectedValues.push(selectedOptions[i]);
        }
        return onChange(selectedValues);
    }

    const error = _.get(errors, path) || "";

    let values = _.get(data, path) || "";
    if (!path || path === "") values = data;

    const selectedOptions =
        options && values &&
        (valueOnly
            ? options.filter((option) => values.includes(option[valueAttr]))
            : options.filter((option) =>
                values.some((value) => value[valueAttr] === option[valueAttr])
            ));
    return (
        <div className={className}>
            {label ? (
                <label
                    htmlFor={values}
                    className="block text-sm font-medium text-gray-900"
                >
                    {label}
                </label>
            ) : null}
            {error && <div className="text-sm text-red-500">{error}</div>}
            <ReactSelect
                className="mt-1"
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                options={options}
                classNamePrefix={path}
                onChange={handleSelect}
                menuPortalTarget={document.body}
                styles={{menuPortal: base => ({...base, zIndex: 90})}}
                value={selectedOptions}
                getOptionValue={(option) => option[valueAttr]}
                getOptionLabel={(option) => option[labelAttr]}
                placeholder={emptyLabel || "Select"}
                isDisabled={disabled}
                isMulti={true}
                {...rest}
            />
        </div>
    );
}

export function DateInput({
                              path,
                              label,
                              errors,
                              className,
                              data,
                              onChange,
                              todayButton,
                              tomorrowButton,
                              inSevenDaysButton,
                              rowId,
                              ...rest
                          }) {

    const error = getError(rowId, errors, path)
    const value = getValue(rowId, data, path, true)

    return (
        <div className={className}>
            <label htmlFor={path} className="block text-sm font-medium text-gray-900">
                {label}
            </label>

            {error && <div className="text-sm text-red-500">{error}</div>}

            <div className="mt-1 flex flex-row items-center">
                <DatePicker
                    id={path}
                    name={path}
                    selected={value || null}
                    label={label}
                    dateFormat="dd/MM/yyyy"
                    maxDetail="day"
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => onChange(date, path, rowId)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    locale="de"
                    autoComplete="off"
                    {...rest}
                />

                {todayButton &&
                    <SpecialDayInput label="H" description="Heute" path={path} rowId={rowId} onChange={onChange}/>}
                {tomorrowButton &&
                    <SpecialDayInput label="M" description="Morgen" path={path} rowId={rowId} onChange={onChange}/>}
                {inSevenDaysButton &&
                    <SpecialDayInput label="7" description="In 7 Tagen" path={path} rowId={rowId} onChange={onChange}/>}
            </div>
        </div>
    );
}

export function DateTimeInput({
                                  path,
                                  label,
                                  errors,
                                  className,
                                  data,
                                  onChange,
                                  todayButton,
                                  tomorrowButton,
                                  inSevenDaysButton,
                                  isDayPeriods,
                                  typeDayPeriod,
                                  helpText,
                                  ...rest
                              }) {
    const error = _.get(errors, path) || "";

    const isMobile = useIsNarrower(500)

    const [isShowPeriod, setShowPeriod] = useState(false)

    return (
        <div className={className}>
            <label htmlFor={path} className="block text-sm font-medium text-gray-900">
                {label}
            </label>

            {error && <div className="text-sm text-red-500">{error}</div>}

            <div className="mt-1 flex flex-row items-center">
                <DatePicker
                    id={path}
                    name={path}
                    selected={(_.get(data, path) && new Date(_.get(data, path))) || null}
                    label={label}
                    dateFormat="dd/MM/yyyy HH:mm"
                    maxDetail="minute"
                    placeholderText="DD/MM/YYYY HH:MM"
                    onChange={(date) => onChange(date, path)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    showTimeSelect
                    timeFormat="p"
                    locale="de"
                    timeIntervals={15}
                    autoComplete="off"
                    {...rest}
                >
                </DatePicker>

            </div>
            <p className="text-sm text-gray-500">{helpText}</p>
        </div>
    );
}

function SpecialDayInput({
                             label,
                             description,
                             path,
                             rowId,
                             onChange,
                         }) {

    const handleChange = () => {
        const dateNow = new Date().setHours(10, 0, 0, 0)
        const offset = label === "7" ? 7 : label === "M" ? 1 : 0
        const date = new Date(dateNow + offset * 86400000)
        onChange(date, path, rowId)
    }

    return (
        <div
            className={"p-1 ml-2 cursor-pointer text-gray-400 hover:text-gray-500 px-2 py-2 " +
                "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
                "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
                "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
                "focus:ring-blue-500"}
            title={description}
            onClick={handleChange}
        >
            {label}
        </div>
    )

}

export function SelectForStudent({
                                     path,
                                     label,
                                     options,
                                     valueAttr,
                                     labelAttr,
                                     helpText,
                                     emptyLabel,
                                     className,
                                     errors,
                                     data,
                                     onChange,
                                     disabled,
                                     valueOnly,
                                     studentId,
                                     ...rest
                                 }) {

    function handleSelect(selectedOption) {
        if (!selectedOption)
            return onChange({target: {name: path, value: null}}, studentId);
        let value;
        if (valueOnly) value = selectedOption[valueAttr];
        else value = selectedOption;
        return onChange({target: {name: path, value}}, studentId);
    }

    const error = getError(studentId, errors, path)
    const value = getValueForStudent(studentId, data, path) || ""

    const selectedOption =
        options &&
        (valueOnly
            ? options.find((option) => option[valueAttr] === value)
            : options.find((option) => option[valueAttr] === value[valueAttr]));

    return (
        <div className={className}>
            {label ? (
                <label
                    htmlFor={value}
                    className="block text-sm font-medium text-gray-900"
                >
                    {label}
                </label>
            ) : null}
            {error && <div className="text-sm text-red-500">{error}</div>}
            <ReactSelect
                options={options}
                classNamePrefix={path}
                onChange={handleSelect}
                value={selectedOption || ""}
                getOptionValue={(option) => option[valueAttr]}
                getOptionLabel={(option) => option[labelAttr]}
                placeholder={emptyLabel || "Select"}
                isDisabled={disabled}
                {...rest}
            />
        </div>
    );
}

