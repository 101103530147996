import http from "../services/httpService"
import httpNoAuthService from "../services/httpNoAuthService";


export async function requestAudit(data, currentUser) {
    let resp
    if (currentUser) {
        resp = await http.post(`/audits/request/`, data)
    } else {
        resp = await httpNoAuthService.post(`/audits/request/`, data)
    }
    return resp.data
}

// get list of (own) audit runs
export async function getAuditRuns(id, secret) {
    const resp = await http.get(`/audit_runs/`)
    return resp.data
}

// get a single audit run with id & secret
export async function getAuditRunDetail(id, secret) {
    const resp = await httpNoAuthService.get(`/audit_runs/${id}/?secret=${secret}`)
    return resp.data
}


// get a single audit run with id & secret
export async function postAuditRunFeedback(data) {
    const resp = await httpNoAuthService.post(`/audit_run_feedback/`, data)
    return resp.data
}
