import {Link, useParams} from "react-router-dom";
import {React, useEffect, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import {getAuditRunDetail, postAuditRunFeedback} from "../../../api/audits";
import {Page} from "../../layout/Pages";
import env from "react-dotenv";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {ShareButtons} from "../../elements/ShareButtons";
import {PrimaryButton} from "../../elements/Buttons";
import {getDomainFromUrl} from "../../../utils/misc";
import {Helmet} from 'react-helmet-async';
import GaugeComponent from 'react-gauge-component'
import {formatDateTime} from "../../../utils/formatters";
import FeedbackModal from "./FeebackModal";
import {toast} from "react-toastify";
import Joi from "joi";
import {baseHandleChangeNested} from "../../elements/forms/Form";
import {StarIcon} from "@heroicons/react/16/solid";
import classNames from "classnames";

const shareUrl = env.REACT_APP_STAGE === 'local' ? 'https://audit.mermaiddevs.com' : window.location.href

export default function AuditResultCC() {

    const {id, secret} = useParams()
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const successMessage = "Thanks for your feedback!"

    const {data: audit, refetch} = useQuery(
        ["audit_run", id],
        () => getAuditRunDetail(id, secret)
    )

    const [errors, setErrors] = useState({}) // validation errors
    const [data, setData] = useState({
        rating: 0,
        feedback: "",
        run: id
    })

    const schema = {
        rating: Joi.number().min(1).max(5).allow(null),
        feedback: Joi.string().allow('')
    }


    useEffect(() => {
        // while audit status is running, refetch every 30 seconds
        if (audit?.status === 'RUNNING') {
            const interval = setInterval(() => {
                refetch()
                console.log('refetching')
            }, 30000)
            return () => clearInterval(interval)
        }
    }, [audit?.status])

    function handleChange(evt) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema)
    }

    async function saveFeedback() {
        if (data.rating !== 0 || data.feedback !== "") {
            try {
                let newData
                // console.log(data)
                newData = await postAuditRunFeedback(data)
                setData(newData)
                refetch() // refetch audit data
                toast.success(successMessage)
                setFeedbackModalOpen(false)
            } catch (e) {
                console.log(e)
                toast.error("We couldn't save your feedback at this time. Please try again later.")
            }
        }
    }

    if (!audit) return <></>;

    const screenshot = audit.audit_steps.length > 0 && audit.audit_steps[0].screenshot ? audit.audit_steps[0].screenshot : null

    return (
        <Page>
            <FeedbackModal
                open={feedbackModalOpen}
                setOpen={setFeedbackModalOpen}
                data={data} setData={setData}
                errors={errors} setErrors={setErrors}
                schema={schema}
                onSave={saveFeedback}
                handleChange={handleChange}

            />

            <div
                className="max-w-3xl mx-auto sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Audit for {getDomainFromUrl(audit.url)} </h1>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    <PrimaryButton onClick={() => setFeedbackModalOpen(true)}
                                   label="Feedback" />
                </div>
            </div>

            {['ERROR'].includes(audit.status) && <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl">
                {audit.status === 'ERROR' && <AuditError/>}
            </div>}

            {['RUNNING', 'NEW'].includes(audit.status) &&
                <div className="mt-8 max-w-3xl mx-auto gap-6 sm:px-6 lg:max-w-7xl">
                    <AuditProcessing/>
                </div>}

            {/* Audit steps including sharing */}
            {['COMPLETED'].includes(audit.status) &&
                <div className="mt-8 max-w-3xl mx-auto sm:px-6 lg:max-w-7xl">
                    {audit.audit_steps.map((auditStep, index) => (
                        <AuditStep key={index} auditStep={auditStep}
                                   setFeedbackModalOpen={setFeedbackModalOpen}
                                   rating={data.rating}
                                   setRating={(rating) => setData({...data, rating: rating})}
                        />
                    ))}
                </div>}

            {/* Next steps */}
            {/*{['COMPLETED'].includes(audit.status) && <ShareResults/>}*/}

        </Page>
    )
}


// function ShareResults() {
//     return <div className="bg-indigo-100 shadow overflow-hidden sm:rounded-lg">
//         <div className="px-4 py-5 sm:px-6">
//             <h3 className="text-lg leading-6 font-medium text-gray-900">
//                 Share your results
//             </h3>
//         </div>
//         <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
//             <ShareButtons url={shareUrl}/>
//         </div>
//
//         <div className="border-t border-gray-200 px-4 pb-5 sm:px-6">
//             <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
//                 Need some help implementing these suggestions?
//             </h3>
//             <p>Check out our web development services at <a className="text-indigo-700"
//                                                             target="_blank"
//                                                             href="https://mermaiddevs.com">mermaiddevs.com</a></p>
//         </div>
//
//     </div>
// }

function AuditStep({auditStep, setFeedbackModalOpen, setRating, rating}) {

    return (
        <>
            <h2 id="step-title" className="text-lg font-medium text-gray-900">
                {auditStep.get_step_type_display}
            </h2>

            <div className="mt-2 mb-6 grid grid-cols-1 gap-4 lg:grid-flow-col-dense lg:grid-cols-3">

                {auditStep.status === 'ERROR' && <div className="space-y-4 lg:col-start-1 lg:col-span-3">
                    <div className="px-4 py-4 bg-white shadow overflow-hidden sm:rounded-lg">
                        <dl className="grid grid-cols-1 sm:grid-cols-2">
                            <div className="px-4 sm:col-span-2 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Whoops, this didn't go as
                                    planned.
                                </dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                    We weren't able to complete this part of your audit at this time. Our team has been
                                    informed and we'll fix it as soon as possible.
                                </dd>
                            </div>
                            <div className="px-4 py-6 sm:col-span-2 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formatDateTime(auditStep.creation_date)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>}

                {auditStep.status === 'COMPLETED' && <>
                    <div className="space-y-4 lg:col-start-1 lg:col-span-1">

                        <div className="px-4 py-4 bg-white shadow overflow-hidden sm:rounded-lg">
                            <GaugeComponent
                                // type="semicircle"
                                className="gauge text-left"
                                labels={{
                                    valueLabel: {
                                        // formatTextValue: value => value + 'ºC',
                                        matchColorWithArc: true,
                                        style: {fontSize: "35px", fill: "black", textShadow: "0"},
                                        maxDecimalDigits: 0,
                                    }
                                }}
                                pointer={{type: "arrow", elastic: true}}
                                arc={{
                                    // width: 0.2,
                                    // padding: 0.00,
                                    // cornerRadius: 0,
                                    // gradient: true,
                                    subArcs: [
                                        {
                                            limit: 50,
                                            color: '#EA4228',
                                            showTick: false
                                        },
                                        {
                                            limit: 84,
                                            color: '#F5CD19',
                                            showTick: false
                                        },
                                        {
                                            limit: 85,
                                            color: '#5BE12C',
                                            showTick: false
                                        },
                                        {
                                            limit: 100,
                                            color: '#5BE12C',
                                            showTick: false
                                        },
                                    ]
                                }}
                                value={auditStep.get_x_out_of_y_passed ? auditStep.get_x_out_of_y_passed.passed / auditStep.get_x_out_of_y_passed.total * 100 : 0}
                            />
                        </div>

                        <div className="px-4 py-4 bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="py-4 sm:py-2 sm:px-2 overflow-y-scroll max-h-[500px]">
                                {auditStep.screenshot && <img src={auditStep.screenshot}
                                                              className="lazy-load-image-background"


                                />}
                            </div>
                            <span className="mt-6 italic text-gray-400 text-xs leading-4">Scroll in within the image to see the entire screenshot.</span>
                        </div>


                    </div>

                    {/* right column */}
                    <div className="space-y-3 lg:col-span-2">
                        <div className="px-4 py-4 bg-white shadow overflow-hidden sm:rounded-lg">
                            <dl className="grid grid-cols-1 sm:grid-cols-2">
                                <div className="px-4 sm:col-span-2 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Tested URL</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{auditStep.url}</dd>
                                </div>
                                <div className="px-4 py-6 sm:col-span-2 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Result</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2 whitespace-pre-wrap"
                                        dangerouslySetInnerHTML={{__html: auditStep.result}}>
                                    </dd>
                                </div>

                                <div className="px-4 py-4 sm:col-span-2 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formatDateTime(auditStep.creation_date)}</dd>
                                </div>

                                <div className="px-4 py-4 sm:col-span-2 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Rate these results</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                        <StepFeedback setFeedbackModalOpen={setFeedbackModalOpen} setRating={setRating} rating={rating} />
                                    </dd>
                                </div>

                                {/*{!userInfo?.has_active_subscription &&*/}
                                {/*    <div className="px-4 py-6 sm:col-span-2 sm:px-0">*/}
                                {/*        <dt className="text-sm font-medium leading-6 text-gray-900">Want to get even more tips on*/}
                                {/*            how to optimise your store?</dt>*/}
                                {/*        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2 whitespace-pre-wrap">*/}
                                {/*            <p>Access additional audit types and credits when you upgrade to*/}
                                {/*                our premium subscription.</p>*/}
                                {/*            <PrimaryButton classNameIn="mt-2" label="Upgrade now" link="/"/>*/}
                                {/*        </dd>*/}
                                {/*    </div>}*/}
                            </dl>
                        </div>


                    </div>
                </>}


            </div>


        </>
    );
}

function StepFeedback({setFeedbackModalOpen, setRating, rating}) {

    function handleClick(value) {
        setRating(value)
        setFeedbackModalOpen(true)
    }

    return (
        <div className="flex">
            {[1, 2, 3, 4, 5].map((value) => (
                <StarIcon
                    key={value}
                    className={classNames(
                        rating >= value ? 'text-indigo-600' : 'text-gray-200',
                        'h-10 w-10 flex-shrink-0 cursor-pointer'
                    )}
                    onClick={() => handleClick(value)}
                    aria-hidden="true"
                />
            ))}
        </div>
    )
}

const AuditProcessing = () => {
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

            <div className="px-4 py-5 sm:px-6 flex">

                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="indigo"
                            strokeWidth="4"></circle>
                    <path className="opacity-75" fill="indigo"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>

                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Running your audit...
                </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6  ">
                <p>This usually takes a few minutes. You can leave this page and come back later. <br/>
                    We'll also send you an email when your results are
                    ready.</p>
            </div>
        </div>
    )
}


const AuditError = () => {
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

            <div className="px-4 py-5 sm:px-6 flex">
                <XCircleIcon className="h-6 w-6 mr-2 text-red-400" aria-hidden="true"/>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Unfortunately, we couldn't complete your audit.
                </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6  ">
                <p>Our team has been notified and we'll fix it as soon as possible.</p>
            </div>
        </div>
    )
}


const AuditResultsHeader = () => {
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

            <div className="px-4 py-5 sm:px-6 flex">

                <CheckCircleIcon className="h-6 w-6 mr-2 text-green-400" aria-hidden="true"/>

                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Your audit results are ready.
                </h3>
            </div>
            {/*<div className="border-t border-gray-200 px-4 py-5 sm:px-6  ">*/}
            {/*    /!*<p>Our team has been notified and we'll fix it as soon as possible.</p>*!/*/}
            {/*</div>*/}
        </div>
    )
}