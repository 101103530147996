import http from "./../services/httpService";
import httpNoAuthService from "../services/httpNoAuthService";

export async function getUserInfoAll() {
    const resp = await http.get('/user_info')
    return resp.data
}

export async function getUsersForAdmin() {
    const resp = await http.get('/users_for_admin')
    return resp.data
}

export async function getUserInfo(userId) {
    const resp = await http.get('/user_info/' + userId)
    return resp.data
}

export async function getPermissionsAndNavigation() {
    const resp = await http.get('/users/permissions_and_navigation/')
    return resp.data
}

export async function getUsers() {
    const resp = await http.get('/users/')
    return resp.data
}

export async function addImageToUser(formData) {
    const resp = await http.post(`/users/add_image/`, formData)
    return resp.data
}

export async function createEmailSubscriber(data) {
    const resp = await httpNoAuthService.post(`/email_subscribers/`, data)
    return resp.data
}

export async function getUserRegistrationInfo() {
    const resp = await http.get(`/user_registration/self/`)
    return resp.data
}

export async function getAccountInfo() {
    const resp = await http.get(`/accounts/account_info/`)
    return resp.data
}

export async function registerUser(data) {
    const resp = await httpNoAuthService.post(`/user_registration/`, data)
    return resp.data
}

export async function updateUser(userId, data) {
    const resp = await http.patch(`/user_registration/${userId}/`, data)
    return resp.data
}