import React, {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from "./../../contexts/AuthContext"
import {passwordErrorMessage, passwordRegEx} from "../../utils/regularExpressions";
import Form, {baseHandleChangeNested} from "../elements/forms/Form";
import {FormSectionContent} from "../elements/forms/FormLayout";
import {Input} from "../elements/forms/Inputs";
import {Page} from "../layout/Pages";
import Joi from "joi";
import {toast} from "react-toastify";
import {registerUser} from "../../api/accounts";
import {CheckIcon} from "@heroicons/react/16/solid";

const features = [
    'Get started for free, no credit cart required',
    'Option to upgrade to access all audit types & up to 100 audits per day',
    'Find all your past audits in your dashboard',
    'Get notified when we release new features',
]
export default function Register() {

    const {state} = useLocation()

    const {login} = useAuth()
    const [errors, setErrors] = useState({}) // validation errors
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [data, setData] = useState({
        // test only
        // "email": "test@google.com",
        // "password": "test123**",
        // "confirm_password": "test123**",
    })

    const schema = {
        first_name: Joi.string().required().label("First name"),
        last_name: Joi.string().allow(null, '').label("Last name"),
        email: Joi.string()
            .required()
            .email({tlds: {allow: false}})
            .label("Email"),
        password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
            .messages({"string.pattern.base": passwordErrorMessage}),
        confirm_password: Joi.any()
            .valid(Joi.ref('password')).label("Confirm Password")
            .messages({"any.only": "Passwords must match"})
    }

    async function onSave() {

        try {

            delete data.confirm_password // does not need to be sent to API

            const newData = await registerUser(data)

            // login the new user
            await login(data.email, data.password)

            setData(newData)

            toast.success(successMessage)
            navigate(state?.prev || "/subscription")
        } catch (ex) {
            if (ex.response.data && ex.response.data.includes("duplicate key value violates unique constraint")) {
                errors["email"] = "This email address is already registered. Please try logging in instead.";
                toast.error(errors["email"]);
            }
            throw (ex)
        }
    }

    function handleChange(evt) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema)
    }

    const title = "Register"
    const successMessage = "Success!"

    return (
        <>
            <Page title={title}>

                <div className="sm:grid sm:grid-cols-2 sm:gap-4 py-4">

                    <div className="sm:col-span-1">
                        <ul
                            role="list"
                            className="text-gray-600 mt-2 space-y-3 text-sm leading-6"
                        >
                            {features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon
                                        className="text-indigo-600 h-6 w-5 flex-none"
                                        aria-hidden="true"
                                    />
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="sm:col-span-1">

                        <Form id="runAudit" onSave={onSave}
                              submitButtonLabel={"Register"}
                              data={data} setData={setData}
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              schema={schema}>

                            <FormSectionContent>

                                <Input
                                    path="first_name"
                                    type="text"
                                    label="First name"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                                <Input
                                    path="last_name"
                                    type="text"
                                    label="Last name"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Input
                                    path="email"
                                    type="email"
                                    label="Email"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Input
                                    path="password"
                                    type="password"
                                    label="Password"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Input
                                    path="confirm_password"
                                    type="password"
                                    label="Confirm Password"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                            </FormSectionContent>
                        </Form>
                    </div>

                </div>
            </Page>

        </>
    )
}
