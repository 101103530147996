import React from 'react';
import {Route, Routes} from "react-router-dom";
import Subscribe from "./Subscribe";


function SubscriptionRouter() {

    return (
        <Routes>
            <Route index={true} element={<Subscribe />} />
            {/*<Route path="/manage" element={<ManageSubscription />} />*/}
        </Routes>
    )
}


export default function Subscriptions() {
    return (
        <SubscriptionRouter />
    )
}
