import React from "react";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from 'react-dom/client';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import env from "react-dotenv";
import {Helmet, HelmetProvider} from 'react-helmet-async';

if (env.REACT_APP_STAGE !== "development" && env.REACT_APP_STAGE !== "local") {
    Sentry.init({
        dsn: "https://d5fff6495e498716722fd8f6073065e4@o320199.ingest.sentry.io/4506510561574912",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_STAGE,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.2, // recommended to be a lower value in production
        // ignoreErrors: ["UnhandledRejection",],
        // Called for message and error events
        beforeSend(event) {
            // Modify or drop the event here
            if (['AxiosError', 'UnhandledRejection'].includes(event.exception.values[0].type)) {
                // console.log('dropping Sentry event')
                return null; // don't send this event
            }
            return event;
        },

    });
}

createRoot(document.getElementById("root")).render(<React.StrictMode>
    <HelmetProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </HelmetProvider>
</React.StrictMode>);

