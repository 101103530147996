import {React, useState} from "react";
import {classNames} from "../../../utils/misc";
import {CheckIcon} from "@heroicons/react/16/solid";
import {useQuery} from "@tanstack/react-query";
import {getPlans} from "../../../api/subscriptions";

const frequencies = [
    {value: 'monthly', label: 'Monthly', priceSuffix: '/month'},
    {value: 'annually', label: 'Annually', priceSuffix: '/year'},
]

export default function Pricing() {

    const [frequency, setFrequency] = useState(frequencies[0])

    const {data: plans} = useQuery(["plans"], getPlans, {refetchInterval: 1000 * 60 * 60})

    const isFeatured = (tier) => {
        return tier.title === "Custom"
    }

    const getLink = (tier) => {
        if (tier.title === "Custom") {
            return "/support"
        } else if (tier.title === "Free") {
            return "/register"
        }
        return "/subscription"
    }

    return (

        <div className="py-10">


            <div className="bg-white py-12 sm:py-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Simple pricing for merchants & agencies
                        </p>
                    </div>
                    {/*<p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">*/}
                    {/*    Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non*/}
                    {/*    voluptas in.*/}
                    {/*    Explicabo id ut laborum.*/}
                    {/*</p>*/}

                    <div className="mt-8 text-lg text-center rounded-md bg-green-50 p-4 bg-green-50 text-green-800">
                        🎉 Limited time launch offer: Use code <strong>LAUNCH50</strong> at checkout to save 50% off any
                        plan,
                        for as long as you stay subscribed.
                    </div>

                    <div
                        className="isolate mx-auto mt-10 grid max-w-lg grid-cols-1 gap-6 lg:max-w-7xl lg:grid-cols-4">
                        {plans?.map((tier) => (
                            <div
                                key={tier.id}
                                className={classNames(
                                    isFeatured(tier) ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200',
                                    'rounded-3xl p-8 ring-1 xl:p-10'
                                )}
                            >
                                <h3
                                    id={tier.id}
                                    className={classNames(
                                        tier.title === "Custom" ? 'text-white' : 'text-gray-900',
                                        'text-lg font-semibold leading-8'
                                    )}
                                >
                                    {tier.title}
                                </h3>
                                {tier.description && <p className={classNames(tier.title === "Custom" ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                                    {tier.description}
                                </p>}
                                <p className="mt-6 flex items-baseline gap-x-1">
                <span
                    className={classNames(
                        tier.title === "Custom" ? 'text-white' : 'text-gray-900',
                        'text-4xl font-bold tracking-tight'
                    )}
                >
                  {tier.price_label ? tier.price_label : tier.price_per_month}
                </span>
                                    {!tier.price_label ? (
                                        <span
                                            className={classNames(
                                                isFeatured(tier) ? 'text-gray-300' : 'text-gray-600',
                                                'text-sm font-semibold leading-6'
                                            )}
                                        >
                    {frequency.priceSuffix}
                  </span>
                                    ) : null}
                                </p>
                                <a
                                    href={getLink(tier)}
                                    aria-describedby={tier.id}
                                    className={classNames(
                                        isFeatured(tier)
                                            ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                                            : 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600',
                                        'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                    )}
                                >
                                    {tier.cta}
                                </a>
                                <ul
                                    role="list"
                                    className={classNames(
                                        isFeatured(tier) ? 'text-white' : 'text-gray-600',
                                        'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                                    )}
                                >
                                    {tier.features.split("\r\n")?.map((feature) => (
                                        <li key={feature} className='flex gap-x-3'>
                                            <CheckIcon
                                                className={classNames(isFeatured(tier) ? 'text-white' : 'text-indigo-600', 'h-6 w-5 flex-none')}
                                                aria-hidden="true"
                                            />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>

                    <p className="mx-auto mt-6 max-w-2xl text-center text-md leading-8 text-gray-600">
                        Prices in USD and excluding Australian GST where applicable.
                    </p>
                </div>
            </div>
        </div>

    )
}
