import {React, useState} from "react";
import Joi from "joi"
import {Input} from "../elements/forms/Inputs"
import Form, {baseHandleChangeNested} from "../elements/forms/Form"
import {createEmailSubscriber} from "../../api/accounts";
import Alert from "./Alert";
import publicIp from "react-public-ip";

export default function EmailSubscribeForm({headerVersion=false}) {

    const [data, setData] = useState({
        "email": ""
    })

    const [errors, setErrors] = useState({}) // validation errors
    const [apiErrors, setApiErrors] = useState() // validation errors
    const [apiSuccess, setApiSuccess] = useState() // validation errors

    const schema = {
        email: Joi.string().email({tlds: {allow: false}}).required()
    }

    const successMessage = "Thanks for signing up!"

    async function onSave() {
        try {
            const ip = await publicIp.v4() || "";
            const tmpData = {...data, ip}
            await createEmailSubscriber(tmpData)
            setData({})
        } catch (e) {
            throw (e)
        }
    }

    function handleChange(evt) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema, true)
    }

    if (headerVersion) {
        return (
            <div className="">
                {apiSuccess && <Alert extraClassName="py-1" variant="success">{apiSuccess}</Alert>}

                {!apiSuccess && <Form id="emailSubscribe" onSave={onSave}
                      submitButtonLabel={"Let's go!"}
                      hideFormLayout={true}
                      setApiErrors={setApiErrors}
                      setApiSuccess={setApiSuccess}
                      data={data} setData={setData}
                      errors={errors} setErrors={setErrors}
                      successMessage={successMessage}
                      schema={schema}>

                    <div className="mt-0 sm:flex sm:max-w-md lg:mt-0">
                        <Input
                            path="email"
                            type="email"
                            label="Your email address"
                            placeholder="Enter your email"
                            autoComplete="email"
                            className="hide-label"
                            inputClassName="-mt-1 w-full min-w-0 appearance-none rounded-md border-0
                            bg-white/5 px-2 py-1 text-base text-grey-900 shadow-sm ring-1 ring-inset ring-indigo-500
                            placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-56 sm:text-sm sm:leading-6"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                        />

                        <div className="mt-0 sm:ml-2 sm:mt-0 sm:flex-shrink-0">
                            <button
                                type="submit"
                                className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-1.5
                                text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            >
                                Join now
                            </button>
                        </div>
                    </div>

                    <div className=" sm:flex sm:max-w-md">
                        {apiErrors && <Alert extraClassName="py-2" variant="danger">{apiErrors}</Alert>}

                    </div>

                </Form>}
            </div>

        )
    }

    return (
        <div className="">
            <Form id="emailSubscribe" onSave={onSave}
                  submitButtonLabel={"Let's go!"}
                  hideFormLayout={true}
                  setApiErrors={setApiErrors}
                  setApiSuccess={setApiSuccess}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <div className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                    <Input
                        path="email"
                        type="email"
                        label="Your email address"
                        placeholder="Enter your email"
                        autoComplete="email"
                        className="hide-label"
                        inputClassName="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-56 sm:text-sm sm:leading-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <div className="mt-4 sm:ml-4 sm:mt-1 sm:flex-shrink-0">
                        <button
                            type="submit"
                            className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>

                <div className="mt-2 sm:flex sm:max-w-md">
                    {apiErrors && <Alert extraClassName="py-2" variant="danger">{apiErrors}</Alert>}
                    {apiSuccess && <Alert extraClassName="py-2" variant="success">{apiSuccess}</Alert>}
                </div>

            </Form>
        </div>

    )
}
