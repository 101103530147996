import {Outlet} from "react-router-dom";
import {Fragment, useState} from "react";
import {Bars3Icon, XMarkIcon,} from "@heroicons/react/24/outline";
import Footer from "./Footer";
import {Navigation, UserNavigation} from "./Navigation";
import {Disclosure} from '@headlessui/react'
import Logo, {LogoAI} from "./Logo";
import EmailSubscribeForm from "../elements/EmailSubscribeForm";


export default function MainLayout({hideSecondaryNav}) {

    const [showBanner, setShowBanner] = useState(false)

    return (
        <>
            <div className="min-h-full">
                {showBanner && <div
                    className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2 sm:px-3.5 sm:before:flex-1">
                    <div
                        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                            style={{
                                clipPath:
                                    'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                            }}
                        />
                    </div>
                    <div
                        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                            style={{
                                clipPath:
                                    'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                            }}
                        />
                    </div>
                    <div className="flex flex-wrap items-center gap-x-4 gap-y-2">

                        <p className="text-sm leading-6 text-gray-900">
                            <strong>Subscribe for feature updates & special deals:</strong>
                        </p>

                        <EmailSubscribeForm headerVersion={true} />
                    </div>
                    <div className="flex flex-1 justify-end">
                        <button onClick={() => setShowBanner(false)} type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                        </button>
                    </div>
                </div>}

                <Disclosure as="nav" className="border-b border-gray-200 bg-white">
                    {({open}) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 justify-between">
                                    <div className="flex">
                                        <div className="flex flex-shrink-0 items-center">
                                            <div className="flex flex-col gap-0">
                                                <LogoAI classNameIn="inline" width={146} height={68}/>
                                            </div>
                                        </div>
                                        <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                            <Navigation mobile={false}/>
                                        </div>
                                    </div>
                                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                        {/*<button*/}
                                        {/*    type="button"*/}
                                        {/*    className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"*/}
                                        {/*>*/}
                                        {/*    <span className="absolute -inset-1.5"/>*/}
                                        {/*    <span className="sr-only">View notifications</span>*/}
                                        {/*    <BellIcon className="h-6 w-6" aria-hidden="true"/>*/}
                                        {/*</button>*/}

                                        <UserNavigation
                                            mobile={false}
                                            navClass="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            itemClass="block px-4 py-2 text-sm text-gray-700"
                                        />

                                    </div>
                                    <div className="-mr-2 flex items-center sm:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <span className="absolute -inset-0.5"/>
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="sm:hidden">
                                <div className="space-y-1 pb-3 pt-2">
                                    <Navigation mobile={true}/>
                                </div>
                                <div className="border-t border-gray-200 pb-3 pt-4">
                                    <UserNavigation mobile={true}/>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                <div>
                    <Outlet/>
                </div>

                <Footer/>
            </div>

        </>
    );
}
