export default function Logo({white=false, width=292, height=135, classNameIn}) {
    return (
        <>
            {white && <img
                height={height} width={width}
                className={classNameIn ? classNameIn : "py-4 px-2"}
                src="/img/logo-wh.svg"
                alt="Mermaid Devs"
            />}
            {!white && <img
                height={height} width={width}
                className={classNameIn ? classNameIn : "py-4 px-2"}
                src="/img/logo.svg"
                alt="Mermaid Devs" />}
        </>
    )
}

export function LogoAI({white=false, width=292, height=135, classNameIn}) {
    return (
        <>
            {white && <img
                height={height} width={width}
                className={classNameIn ? classNameIn : "py-4"}
                src="/img/logo-ai-white.png"
                alt="Mermaid Devs" />}
            {!white && <img
                height={height} width={width}
                className={classNameIn ? classNameIn : "py-4"}
                src="/img/logo-ai-blue.png"
                alt="Mermaid Devs" />}
        </>
    )
}
