import http from "./../services/httpService";
import httpNoAuthService from "../services/httpNoAuthService";

export async function getPlans() {
    const resp = await httpNoAuthService.get(`/plans/`)
    return resp.data
}

export async function createSubscription(data) {
    const resp = await http.post(`/subscriptions/`, data)
    return resp.data
}

export async function getHostedPageURL(data) {
    const resp = await http.post(`/subscriptions/generate_checkout_new_url/`, data)
    return resp
}

export async function getPortalSession(data) {
    const resp = await http.post(`/subscriptions/get_portal_session/`, data)
    return resp.data
}
