import env from "react-dotenv";


const local = {
    API_URL: "http://localhost:8000/api",
    ADMIN_URL: "http://localhost:8000/admin",
    CHARGEBEE_SITE: "mermaiddevs-test",
}

const staging = {
    API_URL: "https://api-staging.schlosstorgelow.de/api/",
    ADMIN_URL: "https://api-staging.schlosstorgelow.de/admin/",
    CHARGEBEE_SITE: "mermaiddevs-test",
}

const production = {
    API_URL: "https://devaudit.mermaiddevs.com/api",
    ADMIN_URL: "https://devaudit.mermaiddevs.com/admin",
    CHARGEBEE_SITE: "mermaiddevs",
}


let config;

if (env.REACT_APP_STAGE === 'local') {
    config = local
} else if (env.REACT_APP_STAGE === 'production') {
    config = production
} else {
    config = staging
}

export default config;
