import {useAuth} from "./../../contexts/AuthContext"
import {useState, useEffect} from 'react'

import {Link, useNavigate} from "react-router-dom"
import Logo from "../layout/Logo";
import Alert from "../elements/Alert";

export default function Logout() {

    const { logout } = useAuth()
    const navigate = useNavigate()
    const [error, setError] = useState("")

    useEffect(() => {
        try {
            logout()
            window.location = "/"
            // navigate("/", { replace: true })
        } catch {
            setError("Error logging you out")
        }
    }, [])

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-indigo">
                        <Logo />
                    </div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-700">Logout</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                        {error && <Alert variant="danger">{error}</Alert>}

                        <div className="my-6">

                            <div className="my-6">

                                <div className="text-sm text-center">
                                    <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Log back in
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
