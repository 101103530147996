import React from 'react';
import {Route, Routes} from "react-router-dom";
import RequestAudit from "./RequestAudit";
import AuditResult from "./AuditResult";

function AuditRouter() {

    return (
        <Routes>
            {/*<Route index={true} element={<RequestAudit />} />*/}
            {/*<Route path="run" element={<RequestAudit />} />*/}
            {/*<Route path=":id/:secret" element={<AuditResult />} />*/}
            {/*<Route path=":id/edit" element={<CourseEdit />} />*/}
        </Routes>
    )
}


export default function Audits() {
    return (
        <AuditRouter />
    )
}
