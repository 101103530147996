import {Link} from "react-router-dom";
import {React, useRef} from "react";
import {classNames} from "../../utils/misc";
import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    CalendarIcon,
    ClockIcon,
    ListBulletIcon,
    PencilIcon,
    PlusIcon as PlusIconMini,
    XMarkIcon
} from "@heroicons/react/20/solid";
import fileDownload from "js-file-download";
import {toast} from "react-toastify";
import {useAuth} from "../../contexts/AuthContext";


function PrimaryButton({label, link, classNameIn, disabled, ...rest}) {

    const button = <button type="button"
                           className={classNames("cursor-pointer inline-flex items-center " +
                               "justify-center px-4 py-2 border border-transparent text-sm font-medium " +
                               "rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 " +
                               "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500" +
                               (disabled ? " opacity-50 cursor-not-allowed" : "")
                               , classNameIn)}
                           disabled={disabled ? "true" : null}
                           {...rest}
    >{label}
    </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }
    return button
}

function SecondaryButton({label, link, classNameIn, ...rest}) {

    const button = <button type="button"
                           className={classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
                               "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
                               "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
                               "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
                               "focus:ring-indigo-500", classNameIn)}
                           {...rest}
    >{label}
    </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }
    return button
}


function DownloadButton({label, fileName, apiFunction, classNameIn, ...rest}) {

    const {permissions} = useAuth(); // this is required for the download to work

    async function handleDownload() {
        try {
            const responseData = await apiFunction()
            fileDownload(responseData, fileName)
        } catch (e) {
            toast.error("Datei konnte nicht heruntergeladen werden")
            // console.log(e)
        }
    }

    const button = <button onClick={handleDownload}
                           type="button"
                           className="cursor-pointer inline-flex items-center justify-center px-4 py-2
                           border border-gray-300 shadow-sm text-sm font-medium rounded-md
                           text-gray-700 bg-white hover:bg-gray-50 focus:outline-none
                           focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                           focus:ring-blue-500"
                           {...rest}
    >{label} <ArrowDownTrayIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
    </button>

    return button
}

function DangerButton({label, link, classNameIn, ...rest}) {

    const button = <button type="button"
                           className={classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
                               "border border-transparent text-sm font-medium rounded-md shadow-sm " +
                               "text-white bg-red-700 " +
                               "hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 " +
                               "focus:ring-offset-gray-100 focus:ring-blue-500", classNameIn)}
                           {...rest}
    >{label}
    </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }
    return button
}


function SuccessButton({label, link, classNameIn, ...rest}) {

    const button = <button type="button"
                           className={classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
                               "border border-transparent text-sm font-medium rounded-md shadow-sm " +
                               "text-white bg-green-500 " +
                               "hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 " +
                               "focus:ring-offset-gray-100 focus:ring-blue-500", classNameIn)}
                           {...rest}
    >{label}
    </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }
    return button
}


function AddButton({label, link, classNameIn, ...rest}) {

    const button = <button
        title={label}
        type="button"
        className={classNames(classNameIn, "cursor-pointer inline-flex items-center rounded-full border border-transparent " +
            "bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2")}
        {...rest}
    >
        <PlusIconMini className="h-5 w-5" aria-hidden="true"/>
    </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }
    return button

}

function RemoveButton({label, classNameIn, ...rest}) {

    return <button
        title={label}
        type="button"
        className="cursor-pointer inline-flex items-center rounded-full border border-transparent
        bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        {...rest}
    >
        <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
    </button>

}


function EditButton({label, link, classNameIn, iconOnly, withBackground, ...rest}) {

    let buttonClassNames
    if (withBackground && iconOnly) {
        buttonClassNames = classNames(classNameIn, "cursor-pointer inline-flex items-center rounded-full border border-transparent " +
            "bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2")
    } else {
        buttonClassNames = iconOnly ? "cursor-pointer text-indigo-700 inline-flex py-2 mx-2"
            : classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
                "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
                "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
                "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
                "focus:ring-blue-500", classNameIn)
    }

    const button =
        <button
            title={label}
            type="button"
            title="Bearbeiten"
            className={buttonClassNames}
            {...rest}
        >

            {!iconOnly && label}
            <PencilIcon className={iconOnly ? "h-5 w-5" : "-mr-1 ml-2 h-5 w-5"} aria-hidden="true"/>
        </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }
    return button

}


function CalendarButton({label, link, classNameIn, iconOnly, ...rest}) {

    const button =
        <button
            title={label}
            type="button"
            title="Kalender"
            className={classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
                "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
                "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
                "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
                "focus:ring-blue-500", classNameIn)}
            {...rest}
        >
            {!iconOnly && label}
            <CalendarIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>

        </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }

    return button
}


function UpdateButton({label, link, classNameIn, iconOnly, ...rest}) {

    const button =
        <button
            title={label}
            type="button"
            title="Aktualisieren"
            className={classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
                "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
                "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
                "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
                "focus:ring-blue-500", classNameIn)}
            {...rest}
        >
            {!iconOnly && label}
            <ArrowPathIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>

        </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }

    return button
}


function ListButton({label, link, classNameIn, iconOnly, rounded, ...rest}) {

    let buttonClassNames
    // if (withBackground && iconOnly) {
    //     buttonClassNames = classNames(classNameIn, "cursor-pointer inline-flex items-center rounded-full border border-transparent " +
    //         "p-2 text-gray-700 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2")
    // } else {
    //     buttonClassNames = classNames("cursor-pointer inline-flex items-center justify-center px-4 py-2 " +
    //         "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
    //         "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
    //         "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
    //         "focus:ring-blue-500", classNameIn)
    // }

    buttonClassNames = classNames((rounded ? "rounded-full p-2 " : " px-4 py-2 ") + "cursor-pointer inline-flex items-center justify-center " +
        "border border-gray-300 shadow-sm text-sm font-medium rounded-md " +
        "text-gray-700 bg-white hover:bg-gray-50 focus:outline-none " +
        "focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 " +
        "focus:ring-blue-500", classNameIn)


    const button =
        <button
            title={label}
            type="button"
            title="Liste"
            className={buttonClassNames}
            {...rest}
        >
            {!iconOnly && label}
            <ListBulletIcon className={iconOnly ? "h-5 w-5" : "-mr-1 ml-2 h-5 w-5"} aria-hidden="true"/>

        </button>

    if (link) {
        return <Link to={link}>{button}</Link>
    }

    return button
}

export {
    PrimaryButton,
    SecondaryButton,
    DangerButton,
    SuccessButton,
    DownloadButton,
    AddButton,
    RemoveButton,
    EditButton,
    CalendarButton,
    ListButton
}