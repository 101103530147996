import './App.css';
import React from 'react'
import AiEcommRouter from "./routing/router";
import {AuthProvider} from "./contexts/AuthContext"
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            networkMode: "always", // default is "online"
            staleTime: 10 * 60 * 1000, // 10 mins
            // refetchInterval: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
})

// Toastify styling via Tailwind
const contextClass = {
    success: "font-medium text-green-800 bg-green-50",
    error: "bg-red-50 text-red-600",
    info: "bg-indigo-600 text-white",
    warning: "text-yellow-600 bg-yellow-50",
    default: "bg-indigo-600",
    dark: "bg-white-600 font-gray-300",
};

function App() {
    return (
        <div className="App">
            <ToastContainer
                toastClassName={({type}) => contextClass[type || "default"] +
                    " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
                }
                bodyClassName={() => "text-sm font-med block p-3"}
                position="top-right"
                autoClose={3000}
            />
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <AiEcommRouter/>
                </AuthProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
