
import {React} from "react";
import {
    EmailIcon,
    EmailShareButton, FacebookIcon,
    FacebookShareButton,
    LinkedinShareButton, RedditIcon,
    RedditShareButton, TelegramIcon,
    TelegramShareButton, TwitterIcon,
    TwitterShareButton, WhatsappIcon,
    LinkedinIcon,
    WhatsappShareButton
} from "react-share";

export const ShareButtons = ({url}) => {
    return <div className="flex gap-2">

        <EmailShareButton url={url} >
            <EmailIcon size={32} round={true} />
        </EmailShareButton>

        <FacebookShareButton url={url} >
            <FacebookIcon size={32} round={true} />
        </FacebookShareButton>

        <LinkedinShareButton url={url} >
            <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>

        <TwitterShareButton url={url} >
            <TwitterIcon size={32} round={true} />
        </TwitterShareButton>

        <RedditShareButton url={url} >
            <RedditIcon size={32} round={true} />
        </RedditShareButton>

        <TelegramShareButton url={url} >
            <TelegramIcon size={32} round={true} />
        </TelegramShareButton>

        <WhatsappShareButton url={url} >
            <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>

   </div>
}