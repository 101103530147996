import {Fragment, React, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import classNames from "classnames";
import {StarIcon} from "@heroicons/react/16/solid";
import Form from "../../elements/forms/Form";
import {TextArea} from "../../elements/forms/Inputs";

export default function FeedbackModal({
                                          open, setOpen,
                                          schema,
                                          data, setData, errors, setErrors,
                                          onSave,
                                          handleChange
                                      }) {

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >


                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <Form id="auditFeedback" onSave={onSave}
                                      hideSubmitButton={true}
                                      hideFormLayout={true}
                                      data={data} setData={setData}
                                      errors={errors} setErrors={setErrors}
                                      successMessage={""}
                                      schema={schema}>
                                    <div>
                                        {/*<div*/}
                                        {/*    className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">*/}
                                        {/*    <QuestionMarkCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true"/>*/}
                                        {/*</div>*/}
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-base font-semibold leading-6 text-gray-900">
                                                Were these results useful?
                                            </Dialog.Title>

                                            <div className="mt-2">
                                                <div className="flex items-center justify-center">
                                                    {[1, 2, 3, 4, 5].map((rating) => (
                                                        <StarIcon
                                                            key={rating}
                                                            className={classNames(
                                                                data.rating >= rating ? 'text-indigo-600' : 'text-gray-200',
                                                                'h-10 w-10 flex-shrink-0 cursor-pointer'
                                                            )}
                                                            onClick={() => setData({...data, rating: rating})}
                                                            aria-hidden="true"
                                                        />
                                                    ))}
                                                </div>

                                                <TextArea
                                                    path="feedback"
                                                    label="We'd love to hear your thoughts:"
                                                    placeholder="We'd love to hear your thoughts"
                                                    // helpText="Your feedback helps us improve"
                                                    className={"text-left mt-4 hide-label"}
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={data}
                                                    rows={4}
                                                />

                                                {/*<p className="text-sm text-gray-500">*/}
                                                {/*    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam*/}
                                                {/*    laudantium explicabo*/}
                                                {/*    pariatur iste dolorem animi vitae error totam. At sapiente aliquam*/}
                                                {/*    accusamus facere veritatis.*/}
                                                {/*</p>*/}
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            No, thanks
                                        </button>
                                    </div>
                                </Form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
