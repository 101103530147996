import axios from "axios";
// import logger from "./logService";
import {toast} from "react-toastify";
import config from "../constants";

const axiosInstance = axios.create({
    baseURL: config.API_URL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use(null, error => {

    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // logger.log(error);
        toast.error("An error occurred. Our team has been notified. " +
            "We apologoise for the inconvenience");
    }

    return Promise.reject(error);
});


const httpNoAuthService = {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete
};

export default httpNoAuthService;
