import {NarrowPage} from "../../layout/Pages";
import {React, useEffect, useState} from "react";
import {Input, RadioGroup, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChangeNested} from "../../elements/forms/Form"
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";
import {getUserRegistrationInfo, updateUser} from "../../../api/accounts";
import {createSubscription, getHostedPageURL, getPlans} from "../../../api/subscriptions";
import {useQuery} from "@tanstack/react-query";
import config from "../../../constants"
import Joi from "joi";
import {countries} from "../../../utils/countries";
import {PrimaryButton} from "../../elements/Buttons";


export default function Subscribe() {

    const {currentUser, refreshToken} = useAuth()
    const [data, setData] = useState({
        "selected_plan": "premium-USD-Monthly",
        "country": "US"
    })
    const [cbInstance, setCbInstance] = useState()
    const [cbLoading, setCbLoading] = useState(false)
    const [showSuccessScreen, setShowSuccessScreen] = useState(false)

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    const schema = {
        address_1: Joi.string().required().label("Address"),
        city_or_suburb: Joi.string().required().label("City"),
        country: Joi.string().required().label("Country")
    }

    const {data: plans} = useQuery(["plans"], getPlans, {refetchInterval: 1000 * 60 * 60})

    const title = "Upgrade for more audits & insights"
    const successMessage = "Success!"

    async function onSave() {

        // save user details first
        const newData = await updateUser(currentUser.user_id, data)

        // Chargebee checkout
        setCbLoading(true)
        cbInstance.openCheckout({
            hostedPage: () => {
                return getHostedPageURL({
                    plan_id: data.selected_plan
                }).then((response) => response.data)
            },
            success: async (hostedPageId) => {

                const subData = {
                    hosted_page_id: hostedPageId,
                    chargebee_plan_id: data.selected_plan
                }
                await createSubscription(subData)
                refreshToken()
                setShowSuccessScreen(true)
            },
            close: () => {
                setCbLoading(false)
            },
            step(step) {
            }
        })

    }

    function handleChange(evt) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema)
    }

    async function loadData() {
        const d = await getUserRegistrationInfo()
        setData({selected_plan: data.selected_plan, ...d})
        if (d.has_active_subscription) setShowSuccessScreen(true)
    }

    useEffect(() => {
        loadData()

        if (!cbInstance) {
            setCbInstance(
                window.Chargebee.init({
                    site: config.CHARGEBEE_SITE
                })
            )
        }

    }, []);

    return (

        <NarrowPage title={title}>

            <div className="sm:grid sm:grid-cols-2 sm:gap-4 py-4">

                {showSuccessScreen && <div className="text-lg sm:col-span-2 py-2">

                    <p>Amazing, you're all set! 🎉</p>

                    <p>You now have access to all types of audits and can run up to 100 audits per day.</p>

                    <PrimaryButton
                        link={`/`}
                        classNameIn={"mt-4"}
                        label="Start your next audit"
                    />
                </div>}

                {!showSuccessScreen && <div className="sm:col-span-2">


                        <Form id="subscriptionCheckout" onSave={onSave}
                              submitButtonLabel={"Let's go!"}
                              data={data} setData={setData}
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              hideSubmitButton={true}
                              schema={schema}>

                            <FormSectionContent>

                                <div className="sm:col-span-6 mb-4">

                                    <div className="text-lg rounded-md bg-green-50 p-4 bg-green-50 text-green-800">
                                        🎉 Limited time launch offer: Use code <strong>LAUNCH50</strong> at checkout to save 50% off any plan,
                                        for as long as you stay subscribed.
                                    </div>

                                    <RadioGroup
                                        key={"selected_plan"}
                                        path="selected_plan"
                                        label="Choose your plan"
                                        className="sm:col-span-6 mt-8"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                        valueAttr="chargebee_id"
                                        labelAttr="full_title"
                                        labelContent="features"
                                        options={plans?.filter(p => p.chargebee_id !== null)}
                                        valueOnly={true}
                                        tiles={true}
                                    />

                                </div>

                                <Input
                                    path="first_name"
                                    type="text"
                                    label="First name"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                                <Input
                                    path="last_name"
                                    type="text"
                                    label="Last name"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Input
                                    path="business_name"
                                    type="text"
                                    label="Business name"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                                <Input
                                    path="tax_id"
                                    type="text"
                                    label="ABN (Australian businesses only)"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Input
                                    path="address_1"
                                    type="text"
                                    label="Address line 1"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                                <Input
                                    path="address_2"
                                    type="text"
                                    label="Address line 2"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Input
                                    path="city_or_suburb"
                                    type="text"
                                    label="City"
                                    className="sm:col-span-2"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                                <Input
                                    path="zip_code"
                                    type="text"
                                    label="Zip code / postcode"
                                    className="sm:col-span-2"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                                <Input
                                    path="state"
                                    type="text"
                                    label="State"
                                    className="sm:col-span-2"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Select
                                    path="country"
                                    label="Country"
                                    className="sm:col-span-6"
                                    options={countries}
                                    valueAttr="code"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    data={data}
                                    errors={errors}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <button
                                    type="submit"
                                    className="sm:col-span-6  justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Proceed to payment
                                </button>

                                <span className="sm:col-span-6 text-center italic text-gray-500 text-sm">Payments powered by Chargebee</span>

                            </FormSectionContent>


                        </Form>

                    <div className="my-4 text-center">
                        <Link to="/"
                              className="font-medium text-indigo-600 hover:text-indigo-500">
                            Skip and continue with a free account
                        </Link>
                    </div>
                </div>}

            </div>
        </NarrowPage>
    )
}
