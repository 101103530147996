import {NarrowPage} from "../../layout/Pages";
import {React, useEffect, useState} from "react";
import {Input, RadioGroup, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChangeNested} from "../../elements/forms/Form"
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";
import {getUserRegistrationInfo, updateUser} from "../../../api/accounts";
import {createSubscription, getHostedPageURL, getPlans} from "../../../api/subscriptions";
import {useQuery} from "@tanstack/react-query";
import config from "../../../constants"
import Joi from "joi";
import {countries} from "../../../utils/countries";
import {PrimaryButton} from "../../elements/Buttons";
import {
    CloudArrowUpIcon, DeviceTabletIcon,
    FingerPrintIcon,
    LockClosedIcon,
    PresentationChartLineIcon,
    SparklesIcon
} from "@heroicons/react/16/solid";
import {ArrowPathIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils/misc";
import {DevicePhoneMobileIcon} from "@heroicons/react/24/outline";

const features = [
    {
        name: 'AI powered analysis with GPT-4 & Vision',
        description:
            'Analyse the data with your own GPT-4 prompts. Apply your own rating criteria or result buckets - the possiblities are (almost) endless.',
        icon: SparklesIcon,
    },
    {
        name: 'Impress your clients with powerful insights',
        description:
            'Use your custom audits for lead generation, showcasing your expertise, or to enhance your existing service offerings and internal workflows.',
        icon: PresentationChartLineIcon,
    },
]


const featuresLeft = [
    {
        name: 'Cross-browser testing, screenshots & interactions',
        description: 'Define your audits with the capabilities of our platform:',
        bulletPoints: [
            'Access websites with a variety of browsers and devices (desktop and mobile)',
            'Interactions such as closing popups, submitting forms, adding products to the cart',
            'Extract data & source code',
            'Take screenshots'
        ],
        icon: DevicePhoneMobileIcon,
    }
]
export default function Agencies() {

    return (

        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Agencies & Professionals</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Turn your expertise into instant, AI powered website audits
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Use our technology to build your own website audits based on custom criteria and supercharge
                        your client services or lead generation.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">

                    <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">

                        <dl className="">
                            {featuresLeft.map((feature) => (
                                <div key={feature.name} className="relative pl-16">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div
                                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>

                                    {feature.bulletPoints &&
                                        <ul role="list"
                                            className="mt-4 space-y-4 list-disc pl-6 text-base leading-7 text-gray-600">
                                            {feature.bulletPoints.map(p => (
                                                <li>{p}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            ))}
                        </dl>

                        <dl className="space-y-10">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-16">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div
                                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>

                                    {feature.bulletPoints &&
                                        <ul role="list"
                                            className="mt-4 space-y-4 list-disc pl-6 text-base leading-7 text-gray-600">
                                            {feature.bulletPoints.map(p => (
                                                <li>{p}</li>
                                            ))}
                                        </ul>
                                    }
                                </div>
                            ))}
                        </dl>

                    </div>

                    {/*<dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">*/}
                    {/*    {features.map((feature) => (*/}
                    {/*        <div key={feature.name} className="relative pl-16">*/}
                    {/*            <dt className="text-base font-semibold leading-7 text-gray-900">*/}
                    {/*                <div*/}
                    {/*                    className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">*/}
                    {/*                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>*/}
                    {/*                </div>*/}
                    {/*                {feature.name}*/}
                    {/*            </dt>*/}
                    {/*            <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>*/}

                    {/*            {feature.bulletPoints &&*/}
                    {/*                <ul role="list"*/}
                    {/*                    className="mt-4 space-y-4 list-disc pl-6 text-base leading-7 text-gray-600">*/}
                    {/*                    {feature.bulletPoints.map(p => (*/}
                    {/*                        <li>{p}</li>*/}
                    {/*                    ))}*/}
                    {/*                </ul>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</dl>*/}


                </div>
                <div className="mx-auto text-center mt-12 max-w-2xl lg:max-w-4xl">

                    <a href="mailto:hello@mermaiddevs.com" type="button"
                            className={classNames("cursor-pointer inline-flex items-center " +
                                "justify-center px-4 py-2 border border-transparent text-sm font-medium " +
                                "rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 " +
                                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                )}
                    >Contact us today to get started
                    </a>
                </div>
            </div>
        </div>
    )
}
