import {NarrowPage} from "../../layout/Pages";
import {React, useEffect, useState} from "react";
import {Input, RadioGroup, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChangeNested} from "../../elements/forms/Form"
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";
import {getUserRegistrationInfo, updateUser} from "../../../api/accounts";
import {createSubscription, getHostedPageURL, getPlans} from "../../../api/subscriptions";
import {useQuery} from "@tanstack/react-query";
import config from "../../../constants"
import Joi from "joi";
import {countries} from "../../../utils/countries";
import {PrimaryButton} from "../../elements/Buttons";

export default function Support() {

    return (

        <NarrowPage title={"Contact & Support"}>

            <div className="min-h-80 sm:grid sm:grid-cols-2 sm:gap-4 py-4">
                <div className="sm:col-span-2">
                    {/*<h2 className="text-md font-bold text-gray-900">Email us</h2>*/}
                    <p>Need help or have a question? Please email us at hello@mermaiddevs.com and we'll get back to you as soon as possible.</p>
                </div>
            </div>
            {/*<div className="sm:grid sm:grid-cols-2 sm:gap-4 py-4">*/}
            {/*    <div className="sm:col-span-2">*/}
            {/*        <h2 className="text-xl font-bold text-gray-900">Frequently Asked Questions</h2>*/}

            {/*        <h3 className="text-md font-bold text-gray-900 mt-2">Email us</h3>*/}
            {/*        <p>Need help? Email us at hello@mermaiddevs.com and we'll get back to you as soon as possible.</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </NarrowPage>
    )
}
