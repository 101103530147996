import React, {useState} from "react";
import Logo from "../layout/Logo";
import {Link, useParams} from 'react-router-dom'
import Alert from './../elements/Alert'

import http from "./../../services/httpNoAuthService";

import Joi from "joi"

import {passwordErrorMessage, passwordRegEx} from "../../utils/regularExpressions";
import {validate, validateProperty} from "../../utils/validation";
import {Input} from "../elements/forms/Inputs"

export default function ResetPassword() {

    const [data, setData] = useState({
        password: null,
        confirm_password: null
    })

    const [errors, setErrors] = useState({}) // validation errors
    const [success, setSuccess] = useState("") // overall success
    const [error, setError] = useState("") // API error
    const [loading, setLoading] = useState(false) // loading to prevent double submits

    const {token, uid} = useParams();

    const schema = {
        password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
            .messages({"string.pattern.base": passwordErrorMessage}),
        confirm_password: Joi.any()
            .valid(Joi.ref('password')).label("Confirm Password")
            .messages({"any.only": "Passwords must match"})
    };

    function handleChange(evt) {
        const value = evt.target.value;

        setData({
            ...data,
            [evt.target.name]: value
        });

        // field validation
        setErrors({
            ...errors,
            [evt.target.name]: validateProperty(evt.target, schema, data)
        });

    }

    async function handleSubmit(e) {

        e.preventDefault()
        setLoading(true)

        // validation of the entire form/schema
        const validationErrors = validate(schema, data)
        setErrors(validationErrors)
        if (validationErrors) {
            setError("Please check your inputs.")
            setLoading(false)
            return
        } else {
            setError("")
        }

        try {
            setSuccess("")

            if (uid) {
                await http.post("/password_reset_with_uid/", {
                    token: token,
                    uidb64: uid,
                    password: data.password
                });
            } else {
                await http.post("/password_reset/confirm/", {
                    token: token,
                    password: data.password
                });
            }

            setSuccess("Your password has been updated. You can now log in again.")

        } catch (e) {
            setError("Your password could not be updated. Please try again or contact our support team.")
        }

        setLoading(false)

    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-indigo">
                        <Logo/>
                    </div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-700">Reset your passowrd</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

                        <form id="passwordResetForm" className="space-y-6" onSubmit={handleSubmit}>

                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}

                            {!success && <>
                                <Input
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    label="New password"
                                    path="password"
                                    type="password"
                                    required
                                />

                                <Input
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    label="Confirm password"
                                    path="confirm_password"
                                    type="password"
                                    required
                                />

                                <div>
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save password
                                    </button>
                                </div>

                            </>}

                        </form>

                        <div className="my-6">
                            <div className="my-6">

                                <div className="text-sm text-center">
                                    <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Back to login
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
