import Logo from "../layout/Logo";
import React, {useRef, useState} from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from "./../../contexts/AuthContext"
import Alert from './../elements/Alert'
import env from "react-dotenv";

export default function Login() {

    const usernameRef = useRef()
    const passwordRef = useRef()
    const {state} = useLocation()

    const {login} = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(usernameRef.current.value, passwordRef.current.value)
            navigate(state?.prev || "/")

        } catch (e) {
            if (e.response && e.response.status === 403) {
                setError("Your account has been disabled due to too many login attempts. Please contact support")
            } else {
                setError("Wrong email or password")
            }
        }

        setLoading(false)
    }

    return (
        <>
            <div className={"min-h-screen flex sm:py-4 md:py-8 sm:px-6 md:px-8"}>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-4 text-center text-2xl font-bold text-indigo">
                            Login</h2>
                    </div>

                    <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
                        <form id="loginForm" className="space-y-6" onSubmit={handleSubmit}>

                            {error && <Alert variant="danger">{error}</Alert>}

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        ref={usernameRef}
                                        name="email"
                                        type="text"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        ref={passwordRef}
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>

                                <div className="text-sm text-right mt-2">
                                    <Link to="/forgot-password"
                                          className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>

                            <div>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Login
                                </button>
                            </div>
                        </form>

                        <div className="my-6">

                            <div className="my-6">


                                <div className="text-sm text-center">
                                    or <Link to="/register"
                                             className="font-medium text-indigo-600 hover:text-indigo-500">
                                Create an account
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
