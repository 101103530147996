import React from "react";
import {ToastContainer} from "react-toastify";
import {Outlet} from "react-router-dom";


export default function EmbeddedLayout() {

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={false}
            />

            <div className="mainAndNavContainer container-full">
                <main className="mainContent">
                    <Outlet/>
                </main>
            </div>

        </>
    );
}
