import React, {useContext, useEffect, useState} from "react"

import jwtDecode from "jwt-decode";
import http from "./../services/httpService";
import httpNoAuth from "./../services/httpNoAuthService";
import config from "../constants";
import {navigation} from "../routing/main_navigation";
import {getAccountInfo, getUserRegistrationInfo} from "../api/accounts";

const apiEndpoint = config.API_URL + "/accounts/";


const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState()
    const [currentUserId, setCurrentUserId] = useState()
    const [loading, setLoading] = useState(true)
    const [permissions, setPermissions] = useState([])
    // const [navigation, setNavigation] = useState([])
    const [currentMainNavItem, setCurrentMainNavItem] = useState()
    const [userInfo, setUserInfo] = useState({})

    async function login(email, password) {
        const response = await httpNoAuth.post(apiEndpoint + "token/", {"username": email, "password": password});
        http.setJwt(response.data.access);
        const jwtDecoded = jwtDecode(response.data.access)
        setCurrentUser(jwtDecoded)
        setCurrentUserId(jwtDecoded.user_id)
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
    }

    function logout() {
        setLoading(true)
        http.post(apiEndpoint + "token/logout/", {
            "refresh_token": localStorage.getItem("refresh_token")
        });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        http.removeJwt();
        setLoading(false)
    }

    async function loginAs(userId) {
        const response = await http.post(config.API_URL + `/users_for_admin/${userId}/token/`);
        const jwtDecoded = jwtDecode(response.data.access)
        setCurrentUser(jwtDecoded)
        setCurrentUserId(jwtDecoded.user_id)
        http.setJwt(response.data.access);
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
    }

    useEffect(() => {

        // temporarily disabled for offline only mode

        // try {
        //     const accessToken = localStorage.getItem('access_token')
        //     setCurrentUser(jwtDecode(accessToken))
        //     setCurrentUserId(jwtDecode(accessToken).user_id)
        //     // load user info
        //
        // } catch (e) {}

        setLoading(false)


    }, [])

    useEffect(() => {

        // temporarily disabled for offline only mode

        // TOOD
        if (!currentMainNavItem) {
            const path = window.location.pathname
            const navItem = navigation.find((item) => item.path === path)
            setCurrentMainNavItem(navItem)
        }
    }, [])

    async function loadUserInfo() {
        // user info
        const d = await getAccountInfo()
        setUserInfo(d)
    }


    // temporarily disabled for offline only mode
    // useEffect(() => {
    //     if (currentUser) loadUserInfo()
    // }, [currentUser])

    const value = {
        currentUser,
        currentUserId,
        permissions,
        navigation,
        setCurrentMainNavItem,
        currentMainNavItem,
        userInfo,
        login,
        logout,
        loginAs
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
