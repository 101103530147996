import {
    collectionPageUrlRegEx,
    homePageOrCartUrlRegEx,
    homePageUrlRegEx,
    productPageUrlRegEx, urlRegEx
} from "./regularExpressions";

const auditTypesList= [
    {
        "title": "Product page",
        "disabled": false,
        "free_access": true,
        "instructions": "Enter a product page URL, usually this is something like https://yourdomain.com/products/your-product",
        "example_url": "https://yourdomain.com/products/your-product",
        "regEx": urlRegEx, //productPageUrlRegEx,
        "error": "Please enter a valid product page URL",
        "remarks": "",
        "code": "PDP"
    },
    {
        "title": "Collection page",
        "disabled": false,
        "free_access": true,
        "instructions": "Enter a collection page URL, usually this is something like https://yourdomain.com/collections/your-collection-name",
        "example_url": "https://yourdomain.com/collections/your-collection-name",
        "regEx": urlRegEx, // collectionPageUrlRegEx,
        "error": "Please enter a valid collection page URL",
        "remarks": "Upgrade to access",
        "code": "PLP"
    },
    {
        "title": "Home page",
        "disabled": false,
        "free_access": true,
        "instructions": "Enter the URL of your homepage, usually this is something like https://yourdomain.com/",
        "example_url": "https://yourdomain.com/",
        "regEx":  homePageUrlRegEx,
        "error": "Please enter a valid URL",
        "remarks": "Upgrade to access",
        "code": "HOME"
    },
    {
        "title": "Cart page",
        "disabled": false,
        "free_access": true,
        "instructions": "Enter the URL of your store or cart, usually something like https://yourdomain.com/cart",
        "example_url": "https://yourdomain.com/cart",
        "regEx":  urlRegEx, //homePageOrCartUrlRegEx,
        "error": "Please enter a valid cart or homepage URL",
        "remarks": "Coming soon",
        "code": "CART"
    },
    {
        "title": "Full store audit",
        "disabled": false,
        "free_access": true,
        "instructions": "Enter the URL of your homepage, usually this is something like https://yourdomain.com/",
        "example_url": "https://yourdomain.com/",
        "regEx":  homePageUrlRegEx,
        "error": "Please enter a valid URL",
        "remarks": "Coming soon",
        "code": "FULL"
    }
]

// potentially move to a backend endpoint (loading time tradeoff?)
export const getAuditTypes = (currentUser, userInfo) => {
    return auditTypesList.map(auditType => {
        if (auditType.free_access && !auditType.disabled) return auditType
        if (currentUser && userInfo.has_active_subscription) {
            if (auditType.disabled) {
                return {...auditType, title: auditType.title + ` (${auditType.remarks})`}
            }
            return auditType
        }
        return {...auditType, disabled: true, title: auditType.title + ` (${auditType.remarks})`}
    })
}
