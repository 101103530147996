import {Link} from 'react-router-dom'

export default function Forbidden() {

    return (
        <>
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Zugriff verweigert</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>
                            Du hast leider keinen Zugriff auf diese Seite.
                        </p>
                    </div>
                    <div className="mt-3 text-sm">
                        <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                            {' '}
                            Zurück zur Startseite <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
