import {Page} from "../../layout/Pages";
import {React, useState} from "react";
import Joi from "joi"
import {Input, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChangeNested} from "../../elements/forms/Form"
import {useNavigate} from "react-router-dom";
import {getAuditTypes} from "../../../utils/options";
import {urlRegEx} from "../../../utils/regularExpressions";
import {useAuth} from "../../../contexts/AuthContext";
import {requestAudit} from "../../../api/audits";
import {toast} from "react-toastify";
import Alert from "../../elements/Alert";
import {
    BoltIcon,
    CloudArrowUpIcon,
    GiftIcon,
    LockClosedIcon,
    ServerIcon,
    SparklesIcon
} from "@heroicons/react/16/solid";
import {BeakerIcon, PuzzlePieceIcon, ShoppingBagIcon} from "@heroicons/react/24/outline";
import {useQueryClient} from "@tanstack/react-query";


const sellingPoints = [
    {
        name: 'Instant results.',
        description:
            'Enter your online store URL - and get your results within minutes.',
        icon: BoltIcon,
    },
    {
        name: 'Built for Shopify stores.',
        description: 'Optimised for ecommerce product pages, collection pages, homepages, cart pages and more.',
        icon: ShoppingBagIcon,
    },
    {
        name: 'Powered by AI.',
        description: 'Actionable, data-driven insights to help you optimise your store, increase ad-spend efficiency, grow your revenue - and delight your customers.',
        icon: SparklesIcon,
    },
]


const features = [
    {
        name: 'Get started for free',
        description:
            'Run up to 10 product page audits per month for free. No credit card required. Upgrade to premium for more page types and runs.',
        icon: GiftIcon,
        href: '/pricing',
        buttonLabel: 'Pricing',
    },
    {
        name: 'More tests coming soon',
        description: 'Analyse more page types, page speed, SEO, and more. Subscribe to our newsletter to be notified when we release them.',
        icon: BeakerIcon,
        href: '#subscribe',
        buttonLabel: 'Subscribe',
    },
    {
        name: 'Build your own audits to offer to your clients',
        description: 'Use our technology to build your own AI-driven marketing audits based on your own expertise & needs. Contact us for more details.',
        icon: PuzzlePieceIcon,
        href: '/build-your-own',
        buttonLabel: 'Learn more',
    },
]

export default function RequestAudit() {

    const {currentUser, userInfo} = useAuth()
    const queryClient = useQueryClient()

    const [data, setData] = useState({
        "audit_type": "PDP"
    })

    const [errors, setErrors] = useState({}) // validation errors
    const [auditValidationErrors, setAuditValidationErrors] = useState([]) // validation errors

    let navigate = useNavigate();

    let auditTypes = getAuditTypes(currentUser, userInfo)
    let regExToUse = auditTypes.find(auditType => auditType.code === data.audit_type)?.regEx || urlRegEx
    let urlErrorMessage = auditTypes.find(auditType => auditType.code === data.audit_type)?.error || "Please enter a valid URL starting with http:// or https://"

    const schema = {
        audit_type: Joi.string().required().messages({
            "string.base": "Please select the audit type you'd like to run",
            "string.required": "Please select the audit type you'd like to run"
        }),
        url: Joi.string().regex(regExToUse).required()
            .messages({
                "object.regex": urlErrorMessage,
                "string.pattern.base": urlErrorMessage
            })
            .label("URL"),
        email: currentUser ? Joi.string().allow('', null) : Joi.string().email({tlds: {allow: false}}).required()
    }

    const successMessage = "Success!"

    async function onSave() {

        // temporarily redirect to an interest form instead - including the email address
        // console.log(data)

        var redirectUrl = `https://mermaiddevs.com/register-your-interest-in-our-ai-website-audit/?wpf100962_3=${data.url}&wpf100962_5=${data.audit_type}&wpf100962_1=${data.email}`
        window.location = redirectUrl

        // try {
        //     let newData
        //     newData = await requestAudit(data, currentUser)
        //     setAuditValidationErrors([])
        //     setData(newData)
        //     queryClient.invalidateQueries({ queryKey: ['audit_runs'] })
        //     toast.success(successMessage)
        //     navigate(`/audit/${newData.id}/${newData.secret}`)
        // } catch (e) {
        //     if (e.data.audit_request_validation_errors) {
        //         setAuditValidationErrors(e.data.audit_request_validation_errors)
        //     }
        //     throw (e)
        // }
    }

    function handleChange(evt) {
        baseHandleChangeNested(evt, data, setData, errors, setErrors, schema)
    }


    return (

        <div className="py-10">

            <div className="py-14 sm:py-20 lg:pb-24 border-b mb-12">
                <div className="lg:py-10 mx-auto max-w-7xl px-6 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-4">
                    <div className="mx-auto max-w-4xl text-left col-span-2">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                            Audit your ecommerce store with the power of AI
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            ... and improve your conversion rates, average order value & overall customer
                            experience.
                        </p>
                        {/*<p className="text-lg leading-8 text-gray-600">*/}
                        {/*    Built for Shopify stores (but might work with other platforms too).*/}
                        {/*</p>*/}
                        <div className="mt-4 flex items-center justify-center gap-x-6">

                            <Form id="runAudit" onSave={onSave}
                                  hideSubmitButton={true}
                                  hideFormLayout={true}
                                  data={data} setData={setData}
                                  errors={errors} setErrors={setErrors}
                                  successMessage={successMessage}
                                  schema={schema}>

                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">

                                    <Select
                                        path="audit_type"
                                        label="Choose your audit type:"
                                        className="sm:col-span-2 text-left"
                                        options={auditTypes}
                                        valueAttr="code"
                                        labelAttr="title"
                                        onChange={handleChange}
                                        data={data}
                                        errors={errors}
                                        isClearable={false}
                                        valueOnly={true}
                                    />

                                    <Input
                                        path="url"
                                        type="url"
                                        label="URL to test:"
                                        placeholder={auditTypes.find(auditType => auditType.code === data.audit_type)?.example_url}
                                        helpText={auditTypes.find(auditType => auditType.code === data.audit_type)?.instructions}
                                        className={"text-left " + (currentUser != null ? "sm:col-span-6" : "sm:col-span-4")}
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />

                                    {currentUser == null && <Input
                                        path="email"
                                        type="email"
                                        label="Your email address:"
                                        placeholder="Your email"
                                        helpText={"We will send the audit results to this email address."}
                                        className="sm:col-span-2 text-left"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                    />}

                                    {auditValidationErrors.length > 0 &&
                                        <Alert extraClassName="py-2 sm:col-span-8" variant="danger">
                                            {auditValidationErrors.map((error, index) => (
                                                <span key={index} className="">{error}</span>
                                            ))}
                                        </Alert>}

                                    <div className="sm:col-span-8">
                                        <button
                                            type="submit"
                                            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                                    bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Test your store
                                        </button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                    </div>

                    <div className="mt-10 max-w-sm mx-auto lg:max-w-auto lg:mt-0 lg:pl-10 col-span-1">
                        <img
                            src={"/img/result_screenshot.png"}
                            alt="Audit result screenshot (example)"
                            className="rounded-xl shadow-lg"
                            style={{zIndex: 1}}
                        />
                    </div>
                </div>
            </div>


            <div className="overflow-hidden bg-white py-2">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div
                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">Optimise your
                                    ecommerce store</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Wondering
                                    why your store isn't converting?</p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Test your site today and find out how to improve your conversion rates and user
                                    experience.
                                </p>
                                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                    {sellingPoints.map((feature) => (
                                        <div key={feature.name} className="relative pl-9">
                                            <dt className="inline font-semibold text-gray-900">
                                                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                                                              aria-hidden="true"/>
                                                {feature.name}
                                            </dt>
                                            {' '}
                                            <dd className="inline">{feature.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                        <div className="flex items-start justify-end lg:order-first relative">
                            <img
                                src={"/img/store.png"}
                                alt="Product screenshot"
                                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                                width={2432}
                                height={1442}
                            />
                            <img
                                src={"/img/gauge.png"}
                                alt="Gauge overlay"
                                className="absolute bottom-0 right-10 w-[24rem] h-auto rounded-xl shadow-lg"
                                style={{zIndex: 1}}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600"></h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            Discover a new way of optimising your online store
                        </p>
                        {/*<p className="mt-6 text-lg leading-8 text-gray-600">*/}
                        {/*    Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a*/}
                        {/*    elementum*/}
                        {/*    pulvinar et feugiat blandit at. In mi viverra elit nunc.*/}
                        {/*</p>*/}
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                            {features.map((feature) => (
                                <div key={feature.name} className="flex flex-col">
                                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                                        <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                        <p className="flex-auto">{feature.description}</p>
                                        <p className="mt-6">
                                            <a href={feature.href}
                                               className="text-sm font-semibold leading-6 text-indigo-600">
                                                {feature.buttonLabel} <span aria-hidden="true">→</span>
                                            </a>
                                        </p>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

        </div>
    )
}
