import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import Login from "../components/auth/Login";
import {useAuth} from "../contexts/AuthContext";
import Forbidden from "../components/auth/Forbidden";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import Dashboard from "../components/dashboard/Dashboard";
import MainLayout from "../components/layout/MainLayout";
import Logout from "../components/auth/Logout";
import React from "react";
import ChangePassword from "../components/modules/settings/ChangePassword";
import EditProfile from "../components/modules/settings/EditProfile";
import SettingsLayout from "../components/modules/settings/SettingsLayout";
import Audits from "../components/modules/audits/Audits";
import LoginAs from "../components/auth/LoginAs";
import RequestAudit from "../components/modules/audits/RequestAudit";
import Register from "../components/auth/Register";
import Subscriptions from "../components/modules/subscriptions/Subscriptions";
import AuditResult from "../components/modules/audits/AuditResult";
import ManageSubscription from "../components/modules/subscriptions/ManageSubscription";
import Support from "../components/modules/pages/Support";
import HowItWorks from "../components/modules/pages/HowItWorks";
import Agencies from "../components/modules/pages/Agencies";
import Pricing from "../components/modules/pages/Pricing";
import EmbeddedLayout from "../components/layout/EmbeddedLayout";
import RequestAuditCC from "../components/modules/audits/RequestAuditCC";
import AuditResultCC from "../components/modules/audits/AuditResultCC";



export default function AiEcommRouter() {

    return (
        <Routes>

            <Route element={<EmbeddedLayout />} >
                <Route path="embedded/cc" element={<RequestAuditCC />} />
                <Route path="embedded/cc/audit/:id/:secret" element={<AuditResultCC />} />
            </Route>

            <Route element={<ProtectedRoutes/>}>

                {/* Protected routes with main layout */}

                {/*<Route element={<MainLayout hideSecondaryNav={true} />}>*/}
                {/*    /!* Messenger *!/*/}
                {/*    <Route path="/messenger/*" element={<Messenger/>} />*/}
                {/*</Route>*/}

                <Route element={<MainLayout/>}>

                    {/*<Route index element={<Dashboard/>}/>*/}
                    {/* TODO temp only */}
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/forbidden" element={<Forbidden/>}/>

                    {/*/!* Calendar *!/*/}
                    {/*<Route*/}
                    {/*    path="/calendar"*/}
                    {/*    element={*/}
                    {/*        <EventProvider>*/}
                    {/*            <Calendar/>*/}
                    {/*        </EventProvider>*/}
                    {/*    }*/}
                    {/*/>*/}
                    <Route path="/audit/*" element={<Audits/>}/>
                    <Route path="/subscription/*" element={<Subscriptions/>}/>

                    <Route path="/users" element={<LoginAs/>}/>

                    {/*/!* Profile and account settings *!/*/}
                    <Route element={<SettingsLayout/>}>
                        <Route path="/settings/change-password" element={<ChangePassword/>}/>
                        <Route path="/settings/profile" element={<EditProfile/>}/>
                        <Route path="/settings/subscription" element={<ManageSubscription />}/>
                        {/*<Route path="/settings/notifications" element={<Notification/>}/>*/}
                    </Route>

                </Route>

            </Route>

            {/* Public routes with no sidebar */}
            {/*<Route element={<NoSidebarLayout/>}>*/}
            {/*    <Route path="/login" element={<Login/>}/>*/}
            {/*    <Route path="/forgot-password" element={<ForgotPassword/>}/>*/}

            {/*    <Route path="reset-password">*/}
            {/*        <Route path=":token" element={<ResetPassword/>}/>*/}
            {/*        <Route path=":token/:uid" element={<ResetPassword/>}/>*/}
            {/*    </Route>*/}
            {/*</Route>*/}



            <Route element={<MainLayout/>}>
                {/* Public audit routes */}
                <Route index element={<RequestAudit/>}/>
                <Route path="audit/run" element={<RequestAudit />} />
                <Route path="audit/:id/:secret" element={<AuditResult />} />

                <Route path="/support" element={<Support/>}/>
                <Route path="/how-it-works" element={<HowItWorks/>}/>
                <Route path="/build-your-own" element={<Agencies/>}/>
                <Route path="/pricing" element={<Pricing/>}/>

                <Route path="/logout" element={<Logout/>}/>
            </Route>

            {/* Public routes with main layout */}
            <Route element={<PublicRoute/>}>
                <Route element={<MainLayout/>}>


                    {/* Auth stuff */}
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>

                    <Route path="/register" element={<Register/>}/>

                    <Route path="reset-password">
                        <Route path=":token" element={<ResetPassword/>}/>
                        <Route path=":token/:uid" element={<ResetPassword/>}/>
                    </Route>
                </Route>
            </Route>


        </Routes>
    );


}

const ProtectedRoutes = () => {
    const {currentUser, loading} = useAuth()
    const location = useLocation()
    return !loading && currentUser ? (
        <Outlet/>
    ) : (
        <Navigate to="/login" replace={true} state={{prev: location.pathname}}/>
    );
};

const RoleBasedRoutes = ({allowedGroups}) => {
    const {currentUser, loading} = useAuth();
    return currentUser?.groups?.find((group) =>
        allowedGroups?.includes(group)
    ) ? (
        <Outlet/>
    ) : (
        <Navigate to="/forbidden" replace={true}/>
    );
};

export const RouteWithPermissionCheck = ({requiredPermission}) => {
    const {currentUser, permissions, loading} = useAuth();
    if (loading || permissions.length === 0) return <></>;
    return currentUser.is_superuser === true ||
    permissions.find((e) => e === requiredPermission) ? (
        <Outlet/>
    ) : (
        <Forbidden/>
    );
};

export const PublicRoute = ({requiredPermission}) => {
    const {currentUser, loading} = useAuth()
    if (loading) return <></>;
    if (currentUser) return <Navigate to="/" replace={true}/>;
    return <Outlet/>
};