import React from "react";
import Logo from "../layout/Logo";
import {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Alert from './../elements/Alert'

import {useAuth} from "./../../contexts/AuthContext"
import http from "./../../services/httpNoAuthService";
import env from "react-dotenv";


const Joi = require('joi');

export default function ForgotPassword() {

    const usernameRef = useRef()

    const { currentUser } = useAuth() // todo if set, return to dashboard?
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setSuccess("")
            setLoading(true)

            await http.post("/password_reset/", {
                email: usernameRef.current.value
            });

        } catch (e) {}

        // we will set this in any case to prevent attacks trying out different users
        setSuccess("Check your email inbox for the link to reset your password.")
        setLoading(false)
    }

    return (
        <>
            <div className={"min-h-screen flex sm:py-4 lg:py-12 sm:px-6 md:px-8 "}>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-4 text-center text-2xl font-bold text-indigo">
                            Password reset</h2>
                    </div>

                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form id="loginForm" className="space-y-6" onSubmit={handleSubmit}>

                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        ref={usernameRef}
                                        name="email"
                                        type="text"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Get reset link
                                </button>
                            </div>
                        </form>

                        <div className="my-6">
                            <div className="my-6">

                                <div className="text-sm text-center">
                                    <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Back to login
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}