import {CheckCircleIcon, InformationCircleIcon, XCircleIcon, ExclamationTriangleIcon} from '@heroicons/react/20/solid'

export default function Alert({extraClassName, variant, children}) {

    let variantColours = {}
    let icon
    switch(variant) {
        case "success":
            variantColours = {
                background: "bg-green-50",
                textColour: "text-green-800"
            }
            icon = <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            break;
        case "warning":
            variantColours = {
                background: "bg-yellow-50",
                textColour: "text-yellow-700"
            }
            icon = <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            break;
        case "danger":
            variantColours = {
                background: "bg-red-50",
                textColour: "text-red-700"
            }
            icon = <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            break;
        default:
            variantColours = {
                background: "bg-blue-50",
                textColour: "text-blue-700"
            }
            icon = <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
    }

    return (
        <div className={"rounded-md bg-green-50 p-4 " + variantColours.background + " " + extraClassName}>
            <div className="flex">
                <div className="flex-shrink-0">
                    {icon}
                </div>
                <div className={"ml-3 text-sm font-medium " + variantColours.textColour}>
                    {children}
                </div>
            </div>
        </div>
    )
}
