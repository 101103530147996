import moment from "moment";

import 'moment/locale/de';  // For German locale
moment.locale('en')

export function formatTimeFromDateTime(dateTime) {
    if (dateTime) {
        return moment(dateTime).format("HH:mm")
    }
    return ""
}

export function formatDateTime(dateTime) {
    return moment.utc(dateTime).local().format("DD MMM yyyy hh:mm A")
}

export function formatDate(date) {
    if (!date) return ""
    return moment.utc(date).local().format("DD MMM yyyy")
}

export function formatDateWithWeekday(date) {
    if (!date) return ""
    return moment.utc(date).local().format("dd DD MMM yyyy")
}

export function formatCurrency(number, decimalPlaces = 2) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: decimalPlaces
    });
    return formatter.format(number)
}