import {useAuth} from "./../../contexts/AuthContext"
import React from "react";
import {Page} from "../layout/Pages";
import ListViewTable from "../elements/tables/ListViewTable";
import {useQuery} from "@tanstack/react-query";
import {getAuditRuns} from "../../api/audits";
import {PrimaryButton} from "../elements/Buttons";


const columns = [
    {
        accessorKey: "url",
        id: "url",
        header: "URL",
        enableColumnFilter: false,
        classNames: "text-indigo-600",
    },
    {
        accessorKey: "get_audit_type_display",
        id: "get_audit_type_display",
        header: "Audit Type",
        enableColumnFilter: false, // todo might change
        // filterFn: "containsString",
    },
    {
        accessorKey: "get_status_display",
        id: "status",
        header: "Status",
        enableColumnFilter: false,
    },
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Date",
        columnType: "date_time",
        enableColumnFilter: false,
    }
]

export default function Dashboard() {

    const {currentUser, permissions} = useAuth()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["audit_runs", currentUser.user_id],
        () => getAuditRuns(),
        {refetchInterval: 1000 *60*2} // 2 minutes
    )

    function getRowLink(row) {
        return `/audit/${row.original.id}/${row.original.secret}`;
    }

    return (
        <Page>
            <div className="">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <PrimaryButton label="Run another audit" link={"/"}/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div
                            className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}
