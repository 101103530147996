import {React, useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import config from "../../../constants"
import {PrimaryButton} from "../../elements/Buttons";


export default function ManageSubscription() {

    const {userInfo} = useAuth()
    const [cbInstance, setCbInstance] = useState(null);

    useEffect(() => {
        const initChargebee = () => {
            setCbInstance(window.Chargebee.init({
                site: config.CHARGEBEE_SITE
            }))
        };
        initChargebee()
    }, []);

    async function handleOpenChargebee(e) {
        var chargebeePortalInstance = cbInstance.createChargebeePortal()
        chargebeePortalInstance.open({
            success: function () {
                // success callback
                // console.log("success")
            },
            close: function () {
                // close callback
                // console.log("close")
            },
            loaded: function () {
                // close callback
                // console.log("loaded")
            },
            error: function (error) {
                // error callback
                // console.log("error")
            }
        })
    }

    return (
        <div className="container">

            <div id="manageSubscription">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Manage Your Subscription</h3>
                        </div>

                        {userInfo?.has_active_subscription && <div className="">

                            <p>You can manage your subscription in Chargebee. To login, enter your email address
                                ({userInfo.email}) and you'll receive a one-time password.</p>
                            <button
                                type="button"
                                onClick={handleOpenChargebee}
                                className="mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Open Chargebee
                            </button>
                        </div>}

                        {!userInfo?.has_active_subscription && <div className="">

                            <p>Access additional audit types and credits when you upgrade to
                                our premium subscription.</p>

                            <PrimaryButton classNameIn="mt-2" label="Upgrade now" link="/"/>
                        </div>}

                    </div>

                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        {/*<button*/}
                        {/*    disabled={loading}*/}
                        {/*    type="submit"*/}
                        {/*    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
                        {/*>*/}
                        {/*    Save*/}
                        {/*</button>*/}
                    </div>
                </div>

            </div>

        </div>
    );
};