import React, {useEffect, useState} from "react";
import Alert from '../../elements/Alert'
import {useAuth} from "../../../contexts/AuthContext"

import http from "../../../services/httpService";

import Joi from "joi"
import {validate, validateProperty} from "../../../utils/validation";
import {Input} from "../../elements/forms/Inputs"
import {addImageToUser, getUsers} from "../../../api/accounts";
import {formatDateTime} from "../../../utils/formatters";
import checkPermission from "../../../utils/permissions";


export default function EditProfile() {

    const {currentUser, permissions} = useAuth()

    const [data, setData] = useState()
    const [parentProfile, setParentProfile] = useState()

    const [errors, setErrors] = useState({}) // validation errors
    const [success, setSuccess] = useState("") // overall success
    const [error, setError] = useState("") // API error
    const [loading, setLoading] = useState(false) // loading to prevent double submits

    const [parentProfileErrors, setParentProfileErrors] = useState({})
    const [parentProfileChanged, setParentProfileChanged] = useState(false)

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const schema = {
        mac_address: Joi.string().allow(null, '')
    };

    const canUpdateProfile = checkPermission(permissions, 'accounts.update_profile')

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        if (!currentUser) return
        const newData = await getUsers()
        setData(newData[0])
    }

    function handleChange(evt) {
        const value = evt.target.value;

        setData({
            ...data,
            [evt.target.name]: value
        });

        // field validation
        setErrors({
            ...errors,
            [evt.target.name]: validateProperty(evt.target, schema, data)
        });

    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    async function handleSubmit(e) {

        e.preventDefault()
        setLoading(true)

        // validation of the entire form/schema
        const validationErrors = validate(schema, data)
        setErrors(validationErrors)
        if (validationErrors) {
            setError("Please check your inputs.")
            setLoading(false)
            return
        } else {
            setError("")
        }

        try {
            setSuccess("")
            let sendData = data;

            // update first
            let {data: newData} = await http.patch("/users/" + currentUser.user_id + "/", sendData);

            // then add the file
            if (isFilePicked) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                newData = await addImageToUser(formData)
            }

            setData(newData)
            setSuccess("Profil aktualisiert.")
        } catch (e) {
            setError("Dein Profil konnte nicht aktualisiert werden.")
        }

        setLoading(false)

    }


    if (!data) return <></>
    return (
        <form id="passwordResetForm" onSubmit={handleSubmit}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Profil bearbeiten</h3>
                        <p className="mt-1 text-sm text-gray-500"></p>
                    </div>

                    <div className="grid grid-cols-2 gap-6">

                        <div className="sm:col-span-2">
                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}
                        </div>

                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:col-span-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Name</dt>
                                <dd className="mt-1 text-sm text-gray-900">{data.first_name} {data.last_name}
                                </dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Letzter Login</dt>
                                <dd className="mt-1 text-sm text-gray-900">{formatDateTime(data.last_login)} </dd>
                            </div>
                        </dl>
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 sm:col-span-2">
                            {canUpdateProfile &&
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                                    <dd className="mt-1 text-sm text-gray-900 col-span-2">
                                        <Input
                                            path="email"
                                            type="email"
                                            label="Email"
                                            className="sm:col-span-6 hide-label"
                                            onChange={handleChange}
                                            errors={errors}
                                            data={data}
                                        />
                                    </dd>
                                </div>}

                            {data.can_update_mac_address && <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">MAC-Adresse</dt>
                                <dd className="mt-1 text-sm text-gray-900 col-span-2">
                                    <Input
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                        label="MAC-Adresse"
                                        path="mac_address"
                                        type="text"
                                        className="sm:col-span-2 hide-label"
                                    />
                                </dd>
                            </div>}
                        </dl>

                        {canUpdateProfile &&
                            <div className="sm:col-span-2">
                                <div className="flex items-center space-x-5">
                                    <div className="flex-shrink-0">
                                        <div className="relative">
                                            {data.image.detail && <img
                                                className="h-32 w-32 rounded-full"
                                                src={data.image.detail}
                                                alt={data.first_name}
                                            />}
                                            <span
                                                className="absolute inset-0 shadow-inner rounded-full"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p>Profilbild</p>
                                        <input type="file" name="profile_image"
                                               onChange={handleChangeFile}/>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>

                </div>

                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

                    <button
                        disabled={loading}
                        type="submit"
                        className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Speichern
                    </button>

                </div>
            </div>

        </form>
    )
}
