import {Link} from "react-router-dom";
import React, {Fragment} from 'react';
import {Menu, Transition} from "@headlessui/react";
import {useAuth} from "../../contexts/AuthContext";
import config from "../../constants";
import {user_navigation} from "../../routing/user_navigation"
import {classNames} from "../../utils/misc";
import {navigation} from "../../routing/main_navigation";
import {PrimaryButton, SecondaryButton} from "../elements/Buttons";

export function Navigation({mobile = false}) {

    const {setCurrentMainNavItem, currentMainNavItem, currentUser} = useAuth()

    return (
        <>
            {navigation.map((item) => {
                if (item.loggedInOnly && !currentUser) return
                return <Link
                    key={item.name}
                    to={item.path}
                    onClick={() => setCurrentMainNavItem(item)}
                    className={classNames(
                        currentMainNavItem?.path === item.path
                            ? 'border-indigo-500 text-gray-900'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        mobile ? 'block border-l-4 py-2 pl-3 pr-4 text-base font-medium' :
                            'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                    )}
                    aria-current={currentMainNavItem?.path === item.path ? 'page' : undefined}
                >
                    {item.name}
                </Link>
            })}

        </>
    )
}

export function UserNavigation({mobile}) {

    const {currentUser, userInfo} = useAuth()

    if (!currentUser) return <div className="flex gap-2">
        <PrimaryButton label="Login" link="/login"/>
        <SecondaryButton label="Register" link="/register"/>
    </div>

    const defaultLinkClasses = mobile ? "block px-2 py-3 text-base text-gray-700" : 'block px-4 py-2 text-sm text-gray-700'

    const menuItems = <div
        className={mobile ? "relative z-[100]" : "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[100]"}>

        {/* Username */}
        {currentUser &&
            <a key="username" href="#"
               className={classNames(mobile ? "block border-b px-2 pb-3 bt-2 text-base text-gray-700" : 'block border-b px-4 py-2 text-sm text-gray-700')}>
                Logged in as {currentUser.first_name ? currentUser.first_name : currentUser.email}
            </a>}

        {user_navigation.map((item) => (
            <Link key={item.name}
                  to={item.path}
                  className={classNames(
                      defaultLinkClasses
                  )}
            >
                {item.name}
            </Link>
        ))}

        {/* Backend */}
        {currentUser && currentUser.is_superuser &&
            <>
                <a key="admin"
                   href={config.ADMIN_URL}
                   className={defaultLinkClasses}
                >
                    Backend
                </a>
                <Link
                    key="admin-login-as"
                    to="/users"
                    className={defaultLinkClasses}
                >
                    Login as
                </Link>
            </>
        }

        {/* Logout */}
        <Link key="logout"
            to="/logout"
            className={classNames(mobile ? 'border-t block px-2 pt-4 py-3 text-base text-gray-700' : 'border-t block px-4 py-2 text-sm text-gray-700')}
        >
            Logout
        </Link>
    </div>

    if (mobile) {
        return <Menu as="div" className="ml-3 relative">{menuItems}</Menu>
    }

    return (
        <Menu as="div" className="main-nav ml-3 relative">
            <div className="flex gap-4">
                {!userInfo?.has_active_subscription && <PrimaryButton label="Upgrade" link="/subscription"/>}
                <Menu.Button
                    className={mobile ? "" : "max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}>
                    <span className="sr-only">Open account menu</span>
                    {!mobile && <img
                        className="h-8 w-8 rounded-full"
                        src={currentUser.avatar || "/img/avatar.png"}
                        alt="Profile"
                    />}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                {menuItems}
            </Transition>

        </Menu>
    )
}